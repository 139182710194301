
// ConnectionForm.js
import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MessageIcon from '@mui/icons-material/Message';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Label } from '@/components/atoms/texts/label';
import { Card } from '@/components/templates/Card';
import { Button } from '@/components/molecules/buttons/button';
import GoogleAuthenticator from '@/components/atoms/GoogleAuthenticator';
import useConnectionForm from './useConnectionForm'; // Adjust this import as needed
import useLogin from '@/pages/login/useLogin';
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
} from "@/components/molecules/inputs/input-otp"
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import { Checkbox } from "@/components/ui/checkbox"


const ConnectionForm = ({ firstConnection = false, resetPassword = false }) => {
    const {
        step,
        formData,
        formErrors, formSuccess,
        code,
        handleChange,
        handleChangeCode,
        handleSubmit,
        nextStep,
        prevStep,
        goToStep,
        srcQRCode, loadingResponse,
        countdown, 
        handleRememberMe, 
        handlePasswordForgotten
    } = useConnectionForm(firstConnection, resetPassword);

    // Utilizing another custom hook for login functionality.
    const { handleLogin } = useLogin();

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const submitButtonRef = useRef(null);


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Empêche le comportement par défaut
          if (submitButtonRef.current) {
            submitButtonRef.current.click(); // Déclenche un clic sur le bouton souhaité
          }
        }
      };

      
    // Mapping titles and descriptions based on the current step of the form.
    const titlesAndDescriptions = {
        0: { title: "resetPassword.cardTitle", description: "resetPassword.cardDescription" },
        1: { title: "resetPassword.cardTitle", description: "resetPassword.cardDescriptionPwd" },

        2: { title: "createAccount.cardDescription", description: "connection.passwordMustContain.generic" },
        3: { title: "createAccount.cardTitleOTP", description: "createAccount.cardDescriptionOTP" },
        4: { title: "connection.cardTitle", description: "connection.cardDescription" },
        // 3: { title: "connection.cardTitleAuth", description: "connection.cardDescriptionAuth" },
        5: { title: "connection.cardTitleCheck", description: "form.code" },
        6: { title: "createAccount.cardTitle", description: "createAccount.tempPwd" },

    };

    // Retrieving the current title and description based on the form's step.
    const { title, description } = titlesAndDescriptions[step] || {};

    // Function to render form content based on the current step.
    const renderContent = () => {

        switch (step) {
            case 0: // Email input fields for the first reset password step.
                return (
                    <div className='flex flex-col gap-4 min-h-44 py-2'>

                        <TextField
                            required
                            id="outlined-requiredEmailReset"
                            label={<Label message={"form.inputMail"} />}
                            type="email"
                            name="resetPwdEmail"
                            value={formData.resetPwdEmail}
                            onChange={handleChange}
                            error={!!formErrors.requestPasswordResetToken}
                            helperText={formErrors.requestPasswordResetToken}
                            autoComplete='username'
                        />

                        {!!formSuccess.checkMail && <div className='bg-success p-2 rounded-sm'><Label className="text-night" fontWeight="semibold" message={"success.checkEmail"}></Label></div>}


                        {/*className="text-night" fontWeight="semibold"  */}

                        {!!formErrors.errorsBackEnd && <Label className="text-error" message={formErrors.errorsBackEnd}></Label>}

                    </div>
                );
            case 1: //  password and password check input fields for the second reset password step.
                const passwordConstraints_newPassword = {
                    length: formData.newPassword.length >= 10 && formData.newPassword.length <= 32,
                    uppercase: /[A-Z]/.test(formData.newPassword),
                    lowercase: /[a-z]/.test(formData.newPassword),
                    digit: /[0-9]/.test(formData.newPassword),
                    specialChar: /[!"#$%&'()*\/+\-.,:;<>?`=@^_{|}~]/.test(formData.newPassword)
                };
                return (
                    <div className='flex flex-col gap-4 min-h-44 py-2'>
                        <div className='flex flex-col '>
                            <Label message={"connection.passwordMustContain.generic"} />
                            <ul className="list-disc pl-5">
                                <li >
                                    <Label message={"connection.passwordMustContain.1"} />
                                    {passwordConstraints_newPassword.length ? <CheckIcon className='ml-2 text-success' /> : formErrors.newPassword && <ErrorOutlineIcon className='ml-2 text-error' />}

                                </li>
                                <li >
                                    <Label message={"connection.passwordMustContain.2"} />
                                    {(passwordConstraints_newPassword.uppercase && passwordConstraints_newPassword.lowercase) ? <CheckIcon className='ml-2 text-success' /> : formErrors.newPassword && <ErrorOutlineIcon className='ml-2 text-error' />}

                                </li>
                                <li >
                                    <Label message={"connection.passwordMustContain.4"} />
                                    {passwordConstraints_newPassword.digit ? <CheckIcon className='ml-2 text-success' /> : formErrors.newPassword && <ErrorOutlineIcon className='ml-2 text-error' />}

                                </li>
                                <li >
                                    <Label message={"connection.passwordMustContain.5"} />
                                    {passwordConstraints_newPassword.specialChar ? <CheckIcon className='ml-2 text-success' /> : formErrors.newPassword && <ErrorOutlineIcon className='ml-2 text-error' />}

                                </li>
                            </ul>
                        </div>
                        <TextField
                            required
                            label={<Label message={"form.inputPassword"} />}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleChange}
                            error={!!formErrors.newPassword}
                            helperText={formErrors.newPassword}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ opacity: '50%', marginRight: '-5px', }}

                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>,
                            }}
                        />

                        <TextField
                            required
                            label={<Label message={"form.inputPasswordCheck"} />}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            name="newPasswordCheck"
                            value={formData.newPasswordCheck}
                            onChange={handleChange}
                            error={!!formErrors.newPasswordCheck}
                            helperText={formErrors.newPasswordCheck}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ opacity: '50%', marginRight: '-5px', }}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>,
                            }}
                        />
                        {!!formErrors.errorsBackEnd && <Label className="text-error" message={formErrors.errorsBackEnd}></Label>}
                        {!!formSuccess.passwordReset && <div className='bg-success p-2 rounded-sm'>
                            <Label className="text-night" fontWeight="semibold" message={"success.passwordReset"}></Label>
                            <Label className="text-night" fontWeight="semibold" message={"success.redirection"}></Label>
                            <Label className="text-night" fontWeight="semibold" >{countdown}</Label>
                        </div>}
                    </div>
                );
            case 2: // password and password check input fields for the activate and configure password step.
                const passwordConstraints_initPassword = {
                    length: formData.initPassword.length >= 10 && formData.initPassword.length <= 32,
                    uppercase: /[A-Z]/.test(formData.initPassword),
                    lowercase: /[a-z]/.test(formData.initPassword),
                    digit: /[0-9]/.test(formData.initPassword),
                    specialChar: /[!"#$%&'()*\/+\-.,:;<>?`=@^_{|}~]/.test(formData.initPassword)
                };

                return (
                    <div className='flex flex-col gap-4 min-h-44 py-2 max-h-[40vh]'>
                        <div className='flex flex-col '>
                            <Label message={"connection.passwordMustContain.generic"} />
                            <ul className="list-disc pl-5">
                                <li >
                                    <Label message={"connection.passwordMustContain.1"} />
                                    {passwordConstraints_initPassword.length ? <CheckIcon className='ml-2 text-success' /> : formErrors.initPassword && <ErrorOutlineIcon className='ml-2 text-error' />}

                                </li>
                                <li >
                                    <Label message={"connection.passwordMustContain.2"} />
                                    {(passwordConstraints_initPassword.uppercase && passwordConstraints_initPassword.lowercase) ? <CheckIcon className='ml-2 text-success' /> : formErrors.initPassword && <ErrorOutlineIcon className='ml-2 text-error' />}

                                </li>
                                <li >
                                    <Label message={"connection.passwordMustContain.4"} />
                                    {passwordConstraints_initPassword.digit ? <CheckIcon className='ml-2 text-success' /> : formErrors.initPassword && <ErrorOutlineIcon className='ml-2 text-error' />}

                                </li>
                                <li >
                                    <Label message={"connection.passwordMustContain.5"} />
                                    {passwordConstraints_initPassword.specialChar ? <CheckIcon className='ml-2 text-success' /> : formErrors.initPassword && <ErrorOutlineIcon className='ml-2 text-error' />}

                                </li>
                            </ul>
                        </div>
                        <TextField
                            required
                            label={<Label message={"form.inputPassword"} />}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            name="initPassword"
                            value={formData.initPassword}
                            onChange={handleChange}
                            error={!!formErrors.initPassword}
                            helperText={formErrors.initPassword}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ opacity: '50%', marginRight: '-5px', }}

                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>,
                            }}
                        />
                        <TextField
                            required
                            label={<Label message={"form.inputPasswordCheck"} />}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            name="initPasswordCheck"
                            value={formData.initPasswordCheck}
                            onChange={handleChange}
                            error={!!formErrors.initPasswordCheck}
                            helperText={formErrors.initPasswordCheck}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ opacity: '50%', marginRight: '-5px', }}

                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>,
                            }}
                        />
                        {!!formErrors.errorsBackEnd && <Label className="text-error" message={formErrors.errorsBackEnd}></Label>}
                    </div>
                );

            case 3: // Qr code pour config otp
                return (
                    <div className='flex flex-col space-y-firstDegree min-h-44 '>

                        <ul className="list-disc pl-5">
                            <li><Label message="createAccount.android" fontWeight="bold" /><Label message="createAccount.androidChoice" /></li>
                            <li><Label message="createAccount.iOS" fontWeight="bold" /><Label message="createAccount.iosChoice" /></li>
                        </ul>

                        <Label message="createAccount.scanQR" />
                        {formData.savedEmail && <span><Label message="connection.email" /><Label> : {formData.savedEmail}</Label></span>}

                        <div className='flex justify-center'>
                            <img src={srcQRCode} className='h-36 w-36' />
                        </div>
                        {!!formErrors.errorsBackEnd && <Label className="text-error" message={formErrors.errorsBackEnd}></Label>}


                    </div>
                );
            case 4: // Email and password input fields for the login step.
                return (
                    <div className='flex flex-col gap-4 min-h-44 py-2'>


                        <TextField
                            required
                            id="outlined-requiredEmailbasic"
                            label={<Label message={"form.inputMail"} />}
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!formErrors.email}
                            helperText={formErrors.email}
                            autoComplete='username'
                        />
                        <TextField
                            required
                            id={"password"}
                            label={<Label message={"form.inputPassword"} />}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            error={!!formErrors.password}
                            helperText={formErrors.password}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ opacity: '50%', marginRight: '-5px', }}

                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>,
                            }}
                        />
                        <div className="flex text-center gap-firstDegree">
                            <Checkbox name="rememberMe" checked={formData.rememberMe} onCheckedChange={handleRememberMe}/>
                            <Label message="connection.rememberMe"></Label>
                        </div>
                        <Button text="connection.forgetPassword"
                            style="ghost"
                            onClick={() => {
                                goToStep(0)
                                // handlePasswordForgotten()
                            }}
                            showLeftIcon={false}
                            leftIcon={<MessageIcon />}
                            sizePadding="small"
                            className="justify-start w-min font-medium hover:underline hover:bg-transparent" />

                        {!!formSuccess.passwordReset && <div className='bg-success p-2 rounded-sm'><Label className="text-night" fontWeight="semibold" message={"success.passwordReset"}></Label> </div>}

                        {/* fontWeight="semibold"  */}


                        {!!formErrors.login && <Label className="text-error" message={formErrors.login}></Label>}
                        {!!formErrors.errorsBackEnd && <Label className="text-error" message={formErrors.errorsBackEnd}></Label>}

                    </div >
                );

            case 5:  // Code verification step OTP
                return (
                    <div className='flex flex-col min-h-44  gap-secondDegree'>
                        {/* <Label fontWeight="semibold" message="form.code" /> */}
                        {formData.savedEmail && <span><Label message="connection.email" className="italic"/><Label> : {formData.savedEmail}</Label></span>}

                        <div className='flex gap-1 md:gap-4 w-full justify-center items-center my-4'>

                            <InputOTP maxLength={6} value={code} onChange={(value) => { handleChangeCode(value) }} className="my-8 w-full">
                                <InputOTPGroup>
                                    <InputOTPSlot index={0} />
                                    <InputOTPSlot index={1} />
                                    <InputOTPSlot index={2} />
                                </InputOTPGroup>
                                <InputOTPSeparator />
                                <InputOTPGroup>
                                    <InputOTPSlot index={3} />
                                    <InputOTPSlot index={4} />
                                    <InputOTPSlot index={5} />
                                </InputOTPGroup>
                            </InputOTP>

                        </div>
                        {!!formErrors.code && <Label className="text-error">{formErrors.code}</Label>}
                        {!!formErrors.otp && <Label className="text-error">{formErrors.otp}</Label>}
                        {!!formErrors.currentUser && <Label className="text-error">{formErrors.currentUser}</Label>}
                        {!!formErrors.errorsBackEnd && <Label className="text-error" message={formErrors.errorsBackEnd}></Label>}

                    </div>
                );
            case 6: //  password and password check input fields for the activate and configure password step.
                return (
                    <div className='flex flex-col gap-4 min-h-44 py-2'>
                        <TextField
                            required
                            id="outlined-requiredEmailActivate"
                            label={<Label message={"form.inputMail"} />}
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!formErrors.email}
                            helperText={formErrors.email}
                        />
                        <TextField
                            required
                            label={<Label message={"form.inputPassword"} />}
                            type={showPassword ? 'text' : 'password'}
                            // autoComplete="current-password"
                            name="tempPassword"
                            value={formData.tempPassword}
                            onChange={handleChange}
                            error={!!formErrors.tempPassword}
                            helperText={formErrors.tempPassword}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ opacity: '50%', marginRight: '-5px', }}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>,
                            }}
                        />
                        <div className="flex text-center gap-firstDegree">
                            <Checkbox name="rememberMe" checked={formData.rememberMe} onCheckedChange={handleRememberMe}/>
                            <Label message="connection.rememberMe"></Label>
                        </div>
                        {!!formErrors.tempPassword && <Label className="text-error" message={formErrors.tempPassword}></Label>}

                        {!!formErrors.errorsBackEnd && <Label className="text-error" message={formErrors.errorsBackEnd}></Label>}


                    </div>
                );

            default: // Additional options provided for testing login functionalities.
                return <div>
                    <Label message="error.notFound"></Label>
                    {/* <Button className="my-2" onClick={() => handleLogin('admin')} text="testLogin.admin" />
                    <Button className="my-2" onClick={() => handleLogin('consultant')} text="testLogin.consultant" /> */}
                </div>;
        }
    };


    // Conditional rendering of the footer based on the current step.
    const renderFooter = () => {
        switch (step) {
            case 0: // Next button for the first step.
                return {
                    footerFilledButtonText: "resetPassword.cardTitle",
                    iconRightBtnFilled: <ArrowForwardIcon />,
                    loadingResponse: loadingResponse

                };
            case 1: // Next button for the first step.
                return {
                    footerFilledButtonText: "form.btnNext",
                    iconRightBtnFilled: <ArrowForwardIcon />,
                    loadingResponse: loadingResponse

                };
            case 2: // Next button for the first step.
                return {
                    footerFilledButtonText: "form.btnNext",
                    iconRightBtnFilled: <ArrowForwardIcon />,
                    loadingResponse: loadingResponse
                    // initPassword
                };
            case 3:
                return {
                    footerFilledButtonText: "form.btnNext",
                    iconRightBtnFilled: <ArrowForwardIcon />,
                    footerOutlinedButtonText: "form.btnBack",

                    // iconLeftBtnOutlined: <ArrowBackIcon />,

                    // onClickBtnOutlined: () => prevStep(),
                    loadingResponse: loadingResponse

                };
            case 4: // Back button with no stretch for the second step.
                return {
                    footerFilledButtonText: "form.btnNext",
                    iconRightBtnFilled: <ArrowForwardIcon />,
                    // onClickBtnGhost: () => goToStep(2),
                    // footerGhostButtonText: "createAccount.noAccount",
                    // iconLeftBtnOutlined: <ArrowBackIcon />,
                    loadingResponse: loadingResponse

                }
            case 5: // Validate button with stretch for the third step.
                return {
                    btnFooterStretch: true,
                    footerOutlinedButtonText: "form.btnBack",
                    iconLeftBtnOutlined: <ArrowBackIcon />,

                    onClickBtnOutlined: () => prevStep(),
                    footerFilledButtonText: "form.btnValidate",
                    iconRightBtnFilled: <DoubleArrowIcon />,
                    loadingResponse: loadingResponse

                };
            case 6: // Next button for the first step.
                return {
                    footerFilledButtonText: "form.btnNext",
                    iconRightBtnFilled: <ArrowForwardIcon />,
                    loadingResponse: loadingResponse

                    // initPassword
                };
            default:
                return {};
        }
    };

    // Rendering the Card component with dynamic title, description, content, and footer based on the current form step.
    return (
        <Card
            title={title} // Titre dynamique basé sur l'étape
            description={description} // Description dynamique basée sur l'étape
            content={<form>{renderContent()}</form>}
            onClickBtnFilled={() => handleSubmit()}
            submitButtonRef={submitButtonRef}
            onKeyDown={handleKeyDown}
            {...renderFooter()}
            className="  md:w-[500px] flex flex-col justify-center  absolute w-[95vw]  bg-white  top-1/2 md:right-0 transform translate-x-[2.5vw]	 md:translate-x-1/2 -translate-y-1/2 rounded-md"

        />
    );
};

export default ConnectionForm;
