import React, { useState, useEffect } from 'react';
import { Progress } from "@/components/molecules/progress"
import { useGetExpenseReportProof_AsUser } from '@/services/consultant-services/api-rest/useGetExpenseReportProof_AsUser';
import { useGetExpenseReportProof_AsAdmin } from '@/services/admin-services/api-rest/useGetExpenseReportProof_AsAdmin';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';

const ImageWithProgress = ({ src, uuidExpenseProof, alt, isForExpenseProof = false, asUser }) => {
    const [progress, setProgress] = useState(0);
    const [imageSrc, setImageSrc] = useState('');
    // console.log('ImageWithProgress : src', src)
    // useEffect(() => {
    //     const loadImage = async () => {
    //         const response = await fetch(src);
    //         const reader = response.body.getReader();
    //         const contentLength = +response.headers.get('Content-Length');

    //         let receivedLength = 0; // taille des octets reçus
    //         let chunks = []; // tableaux d'octets reçus

    //         while (true) {
    //             const { done, value } = await reader.read();

    //             if (done) {
    //                 break;
    //             }

    //             chunks.push(value);
    //             receivedLength += value.length;

    //             // Calcul du pourcentage du chargement
    //             setProgress(Math.round((receivedLength / contentLength) * 100));

    //             // Concaténation des morceaux en une seule Uint8Array
    //             let chunksAll = new Uint8Array(receivedLength);
    //             let position = 0;
    //             for (let chunk of chunks) {
    //                 chunksAll.set(chunk, position);
    //                 position += chunk.length;
    //             }

    //             // Conversion en Blob puis en URL pour l'image
    //             const blob = new Blob([chunksAll]);
    //             setImageSrc(URL.createObjectURL(blob));
    //         }
    //     };

    //     loadImage();
    // }, [src]);
    const { getExpenseProof, loading: loadingExpenseProof, error: errorExpenseProof, data: dataExpenseProof } = asUser ? useGetExpenseReportProof_AsUser() : useGetExpenseReportProof_AsAdmin();

    const handleGetExpenseProof = () => {
        // console.log("handleGetExpenseProof", uuidExpenseProof)
        if (uuidExpenseProof) {
            getExpenseProof(uuidExpenseProof);
        }
    };
// if(!uuidExpenseProof)return(<></>)
    // const handleOpenInvoice = () => {
    //     if (!dataExpenseProof) {
    //         handleGetExpenseProof();
    //     }
    //     dataExpenseProof && window.open(dataExpenseProof?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    // }
    // TODO : mot dans global 
    useEffect(() => {
        // console.log("use effect call handleGetExpenseProof")
         uuidExpenseProof && handleGetExpenseProof()
    }, [uuidExpenseProof]);
    // console.log("dataExpenseProof", dataExpenseProof)
    return (
        <div className='rounded-sm absolute p-1 mx-4 right-[32rem] w-[32rem] flex justify-center bg-medium h-auto max-h-5/6 top-1/2 transform -translate-y-1/2 max-h-[90vh]'>
            {/* {progress < 100 ? (
                <div className='px-4 flex flex-col min-h-[30vh] items-center justify-center gap seconDegree'>
                    <iframe src="https://lottie.host/embed/b9d1ab24-f7e1-4f30-93ef-edfccb506d4c/ciQ4uVHC0P.json" ></iframe>
                    <Progress value={progress} />
                    Chargement : {progress}% 
                </div> */}
            {!loadingExpenseProof && !errorExpenseProof && (
                dataExpenseProof?.type == "application/pdf" ? 
                <iframe src={dataExpenseProof?.url + "#toolbar=0"}  style={{height: '70vh'}} className="w-full rounded-sm" frameborder="0"/> : 
                <img src={dataExpenseProof?.url} alt={alt} loading="lazy" className="w-full rounded-sm" />

                // <img src={imageSrc} alt={alt} loading="lazy" className="w-full rounded-sm" />
            )}
            {loadingExpenseProof && <span className='loader' />}
            {errorExpenseProof && <PrintErrorMessage error={errorExpenseProof} />}

        </div>
    );
};

export default ImageWithProgress;
