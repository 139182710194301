import { Label } from "@/components/atoms/texts/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/molecules/popover";
import { Button } from "@/components/molecules/buttons/button";
import { useEffect, useRef, useState } from "react";
import Polygon1 from "@/assets/Polygon1.svg"
import ControlsDownload from "@/components/molecules/controlsDownload";
import Chip from "@/components/molecules/buttons/Chip";
// import ReadingStatus from "@/components/atoms/ReadingStatus";
import getCurrencySymbolFromCode from "@/utils/getCurrencySymbolFromCode";
import { useTranslation } from "react-i18next";
import getMonthName from "@/utils/getMonthName";
import ReadingStatus from "./readingStatus";
import { useDeletePayslip } from "@/services/admin-services/mutations/useDeletePayslip";
import { useGetPayslip } from "@/services/consultant-services/api-rest/useGetPayslip";
import { useGetConsultantPayslip } from "@/services/admin-services/api-rest/useGetConsultantPayslip";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { useAuth } from "@/context/AuthContext";
import EditAdminModal from "../../consultant-vision-user/expense-account/EditAdminModal";
import NewPayslipPanel from "@/components/macro-organisms/panels/panels-admin/NewPayslip/NewPayslipPanel";
import FormNewPayslip from "@/components/macro-organisms/panels/panels-admin/NewPayslip/FormNewPayslip";
import { useUpdatePayslip } from "@/services/admin-services/mutations/useUpdatePayslip";
import { useStorePayslip } from '@/services/admin-services/api-rest/useStorePayslip';

// "amountTax": { "5": "7.36", "10": "4.53", "12": "7.68" },
export default function Payslip({ data, dataOwner, refetchFromScratch, editable = false }) {

    const symbol = getCurrencySymbolFromCode(data?.currency)
    const [t] = useTranslation("global");
    const { toast } = useToast();

    // import hook for actions ---------------------------------
    const { deletePayslip, loading: loadingDelete, error: errorDelete, data: dataDelete } = useDeletePayslip();
    const { getPayslip, loading: loadingFile, error: errorFile, data: payslipFile } = dataOwner ? useGetConsultantPayslip() : useGetPayslip();
    const { updatePayslip, loading: loadingUpdate, error: errorUpdate, data: dataUpdate } = useUpdatePayslip();
    const { storePayslip, loading: storeLoading, error: storeError, data: storeData } = useStorePayslip();
    const [submissionError, setSubmissionError] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [dataToSend, setDataToSend] = useState(null);
    const submitRef = useRef(null);

    const { hasPrivileges } = useAuth();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);


    // old payslip ? -------------------------------------------
    const providedDate = new Date(data?.createdAt);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - providedDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    // handle functions ----------------------------------------
    const handleGetPayslipFile = () => {
        if (data?.payslipFileUuid) {
            getPayslip(data?.payslipFileUuid);
        }
    };
    const handleOpenPayslipFile = () => {
        if (!payslipFile) {
            handleGetPayslipFile();
        }
        payslipFile && window.open(payslipFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }
    useEffect(() => {
        payslipFile && window.open(payslipFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }, [payslipFile]);


    const handleDelete = async () => {
        try {
            await deletePayslip({ variables: { uuid: data?.uuid } });
        } catch (e) {
            console.error("Error deleting handleDelete:", e);
        }
    };

    const handleSubmitUpdate = async (formData) => {
        setDataToSend(formData);
        console.log("handleSubmitUpdate formData =", formData);

        try {
            setSubmissionError(null);
            setSubmissionSuccess(false);
            if (formData.file) {
                const fileResponse = await formData.file && storePayslip(formData.file, formData?.consultantUuid);
            } else {
                sendUpdatePayslip(null, formData);
            }
        } catch (err) {
            setSubmissionError(err.message);
            console.error("Erreur lors de la soumission:", err);
        }
    };
    const sendUpdatePayslip = async (uuidFile, formData) => {
        console.log("sendUpdatePayslip uuidFile =", uuidFile, formData);
        const updatedPayslip = uuidFile ? {
            payslipFileUuid: uuidFile || formData?.payslipFileUuid || dataToSend?.payslipFileUuid,
            period: formData ? formData?.period : dataToSend?.period,
            currency: formData ? formData?.currency : dataToSend?.currency,
            grossSalary: parseFloat(formData ? formData?.grossSalary : dataToSend?.grossSalary),
            netSalary: parseFloat(formData ? formData?.netSalary : dataToSend?.netSalary),
            taxes: parseFloat(formData ? formData?.taxes : dataToSend?.taxes),
            employerContributions: parseFloat(formData ? formData?.employerContributions : dataToSend?.employerContributions),
            employeeContributions: parseFloat(formData ? formData?.employeeContributions : dataToSend?.employeeContributions),
            comment: formData ? formData?.comment : dataToSend?.comment,
            bonus: formData ? formData?.bonus : dataToSend?.bonus,
            uuid: formData ? formData?.uuid : dataToSend?.uuid
        } : {
            period: formData ? formData?.period : dataToSend?.period,
            currency: formData ? formData?.currency : dataToSend?.currency,
            grossSalary: parseFloat(formData ? formData?.grossSalary : dataToSend?.grossSalary),
            netSalary: parseFloat(formData ? formData?.netSalary : dataToSend?.netSalary),
            taxes: parseFloat(formData ? formData?.taxes : dataToSend?.taxes),
            employerContributions: parseFloat(formData ? formData?.employerContributions : dataToSend?.employerContributions),
            employeeContributions: parseFloat(formData ? formData?.employeeContributions : dataToSend?.employeeContributions),
            comment: formData ? formData?.comment : dataToSend?.comment,
            bonus: formData ? formData?.bonus : dataToSend?.bonus,
            uuid: formData ? formData?.uuid : dataToSend?.uuid
        };

        await updatePayslip({
            variables: { updatedPayslip },
        });
    };
    useEffect(() => {


        if (storeData) {
            dataToSend && sendUpdatePayslip(storeData.uuid);
        }

        storeError && setSubmissionError(storeError);
    }, [storeError, storeData]);

    useEffect(() => {
        if (dataUpdate?.updatePayslip?.uuid) {
            setSubmissionSuccess(true);
            refetchFromScratch();
        }
        errorUpdate && setSubmissionError(errorUpdate);
    }, [errorUpdate, dataUpdate]);

    // TODO : texte à mettre dans global
    useEffect(() => {
        if (dataDelete) {
            toast({
                variant: "success",
                title: "success.backendOperation.delete",
                duration: 8000,
                description: "La fiche de paie " + data?.month + "/" + data?.year + " " + data?.comment + " a été supprimée"
            })
            refetchFromScratch(1)
        } else if (dataDelete?.deleteCompanyBenefitInvoice == false && errorDelete) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorDelete?.message
            })
        }
    }, [dataDelete])

    useEffect(() => {
        if (dataUpdate) {
            console.log(dataUpdate, "dataUpdate");
            toast({
                variant: "success",
                title: "success.backendOperation.update",
                duration: 5000,
                description: "La fiche de paie " + dataUpdate?.updatePayslip?.month + "/" + dataUpdate?.updatePayslip?.year + " a été mise à jour"
            });
            refetchFromScratch(1);
        } else if (dataUpdate?.updatePayslip == false && errorUpdate) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorUpdate?.message
            });
        }
    }, [dataUpdate]);

    return (
        <div className={`bg-white  w-full shadow-md px-paddingLeftAndRight py-paddingTopAndBottom rounded-sm justify-start items-center inline-flex gap-secondDegree`}>
            <ReadingStatus isBonus={data?.bonus} />
            <ReadingStatus isNew={differenceInDays < 30} />

            <Label fontWeight={differenceInDays < 30 && "bold"}>{t("consultants.payslip.salary")} {getMonthName(data?.month)} {data?.year}</Label>

            <div className="grow"></div>
            <Label fontWeight="">{data?.comment}</Label>
            {dataOwner ?
                <div className=" overflow-hidden flex gap-firstDegree flex-col 2xl:flex-row">
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.employeeCharges" />
                        <Label >{data?.employeeContributions} {symbol}</Label>
                    </div><div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.employerCharges" />
                        <Label >{data?.employerContributions} {symbol}</Label>
                    </div>
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.grossSalary" />
                        <Label >{data?.grossSalary} {symbol}</Label>
                    </div>
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.taxsOnSalary" />
                        <Label >{data?.taxes} {symbol}</Label>
                    </div>

                </div>
                :
                <div className="max-w-[70%] overflow-hidden flex gap-firstDegree flex-col xl:flex-row">
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.grossSalary" />
                        <Label >{data?.grossSalary} {symbol}</Label>
                    </div>
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.netAmount" />
                        <Label >{data?.netSalary} {symbol}</Label>
                    </div>
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.taxsOnSalary" />
                        <Label >{data?.taxes} {symbol}</Label>
                    </div>
                </div>

            }

            <Label fontWeight="">{data?.fileSize}</Label>
            {(loadingDelete || loadingFile) && <span className="loader" />}
            <ControlsDownload
                dataOwner={dataOwner}
                onClickNewTab={handleOpenPayslipFile}
                onClickDownload={() => console.log("onClickDownload")}
                onClickDelete={handleDelete}

                disabledOpenNewTab={!data?.payslipFileUuid || errorFile}
                disabledDelete={errorDelete}
                hideEdit={!editable || !hasPrivileges('ADMIN_PAYSLIP')}

                onClickEdit={() => setIsEditModalOpen(true)}
                hideDownload
                titleForDelete={t("consultants.payslip.salary") + " : " + getMonthName(data?.month) + " - " + data?.year}
            />
            {isEditModalOpen && (
                <EditAdminModal
                    data={data}
                    onClose={() => setIsEditModalOpen(false)}
                    type="payslip"
                    form={
                        <FormNewPayslip data={data} forEdition onSubmit={handleSubmitUpdate} submitRef={submitRef} submissionSuccess={submissionSuccess} submissionError={submissionError}/>
                    }
                    onSubmitForm={() => submitRef.current && submitRef.current.click()}
                />
            )}
        </div>
    );
}