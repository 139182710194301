import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Requête pour obtenir toutes les TVA
const GET_ALL_VATS = gql`
  query ALL_VATS($companyUuid: ID!) {
    allVat(companyUuid: $companyUuid) {
      rate
      status
      uuid
    }
  }
`;

// Hook personnalisé
export const useAllVats = () => {
  const { user } = useAuth();
  const companyUuid = user.company.uuid;

  const { loading, error, data, refetch } = useQuery(GET_ALL_VATS, {
    variables: { companyUuid },
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only", // S'assure que les données sont toujours récupérées du serveur
  });

  return { 
    loading, 
    error, 
    data: data?.allVat, 
    refetch 
  };
};

// Hook personnalisé pour obtenir toutes les TVA actives
export const useActiveVats = () => {
  const { user } = useAuth();
  const companyUuid = user.company.uuid;

  const { loading, error, data, refetch } = useQuery(GET_ALL_VATS, {
    variables: { companyUuid },
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only",
  });

  const activeVats = data?.allVat
    .filter(vat => vat.status === "ACTIVE")
    .sort((a, b) => a.rate - b.rate);

  return { 
    loading, 
    error, 
    data: activeVats, 
    refetch 
  };
};
