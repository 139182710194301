import { createContext, useContext, useReducer, useEffect } from 'react';
import { gql, useQuery, useMutation } from "@apollo/client";
import { useAuth } from '@/context/AuthContext';
import { NetworkConnectionLost } from '@/components/organisms/feedback/NetworkConnectionLost';

// Déclaration des requêtes GraphQL
const GET_NOTIFICATIONS_LAST_KNOWN_UUID = gql`
  query Notifications($lastKnownUuid: ID) {
    notifications(lastKnownUuid: $lastKnownUuid) {
      uuid
      createdAt
      type
      nature
      message
      readStatus
      deepLink
    }
  }
`;
const READ_ALL_NOTIFICATIONS = gql`
  mutation ReadAllNotifications($notificationNature: NotificationNature!) {
    readAllNotifications(notificationNature: $notificationNature)
  }
`;

const READ_NOTIFICATION = gql`
  mutation ReadNotification($uuid: ID!) {
    readNotification(uuid: $uuid)
  }
`;

const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($uuid: ID!) {
    deleteNotification(uuid: $uuid)
  }
`;

// Initial state pour le reducer
const initialState = {
    notifications: [],
    informations: [],
    actions: [],
    lastKnownUuid: null,
    error: null,
};

// Création du contexte
const notificationReducer = (state, action) => {
    switch (action.type) {
        case 'SET_NOTIFICATIONS':
            const newNotifications = action.payload;
            const updatedNotifications = state.notifications.map(notification => {
                const updatedNotification = newNotifications.find(newNotif => newNotif.uuid === notification.uuid);
                return updatedNotification ? updatedNotification : notification;
            });

            // Ajoute les nouvelles notifications qui n'existent pas déjà dans le state
            const newUuids = newNotifications.filter(newNotif =>
                !state.notifications.some(existingNotif => existingNotif.uuid === newNotif.uuid)
            );

            const sortedData = [...updatedNotifications, ...newUuids].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            return {
                ...state,
                notifications: sortedData,
                informations: [
                    ...sortedData.filter(notification => notification.nature === 'NOTIFICATION')
                ],
                actions: [
                    ...sortedData.filter(notification => notification.nature === 'ACTION')
                ],
                lastKnownUuid: sortedData.length > 0 ? sortedData[0].uuid : state.lastKnownUuid,
            };
        case 'MARK_AS_READ':
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    notification.uuid === action.uuid ? { ...notification, readStatus: 'READ' } : { ...notification }
                ),
                informations: state.informations.map(notification =>
                    notification.uuid === action.uuid ? { ...notification, readStatus: 'READ' } : { ...notification }
                ),
                actions: state.actions.map(notification =>
                    notification.uuid === action.uuid ? { ...notification, readStatus: 'READ' } : { ...notification }
                ),
            };
        case 'DELETE_NOTIFICATION':
            const updatedNotificationsAfterDelete = state.notifications.filter(notification => notification.uuid !== action.uuid);

            // Recalcule du lastKnownUuid après suppression
            const newLastKnownUuid = updatedNotificationsAfterDelete.length > 0 
                ? updatedNotificationsAfterDelete[0].uuid  // La notification la plus récente devient la nouvelle lastKnownUuid
                : null;  // Si plus aucune notification, on remet à null
        
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.uuid !== action.uuid),
                informations: state.informations.filter(notification => notification.uuid !== action.uuid),
                actions: state.actions.filter(notification => notification.uuid !== action.uuid),
                lastKnownUuid: newLastKnownUuid,  // Mise à jour de lastKnownUuid

            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'RESET_LAST_KNOWN_UUID':
            return {
                ...state,
                lastKnownUuid: null,  // Réinitialise lastKnownUuid à null
            };
        default:
            return state;
    }
};

const NotificationContext = createContext();

// Provider du contexte
export function NotificationProvider({ children }) {
    const { user, isAuth } = useAuth();

    if (!isAuth()) {
        return (
            <NotificationContext.Provider value={{}}>
                {children}
            </NotificationContext.Provider>
        )
    }
    const [state, dispatch] = useReducer(notificationReducer, initialState)
    const { notifications, informations, actions, lastKnownUuid } = state;


    // Utilisation de la requête GraphQL pour obtenir les notifications
    const { loading, error, data, refetch } = useQuery(GET_NOTIFICATIONS_LAST_KNOWN_UUID, {
        variables: { lastKnownUuid },
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
        fetchPolicy: "network-only",
        onError: (err) => {
            dispatch({
                type: 'SET_ERROR',
                payload: err,
            });
        },

    });

    // Utilisation des mutations pour marquer comme lu et supprimer
    const [readNotificationMutation] = useMutation(READ_NOTIFICATION, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
        onError: (err) => {
            console.error("Error marking notification as read:", err);
        },
        // onCompleted: refetch()
    });

    const [deleteNotificationMutation] = useMutation(DELETE_NOTIFICATION, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
        onError: (err) => {
            console.error("Error deleting notification:", err);
        },
         onCompleted: refetch()

    });

    // Utilisation de la mutation pour marquer toutes les notifications comme lues
    const [readAllNotificationsMutation] = useMutation(READ_ALL_NOTIFICATIONS, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
        onError: (err) => {
            console.error("Error marking all notifications as read:", err);
        },
        // onCompleted: refetch(),
    });

    // Effect pour traiter les nouvelles données et mettre à jour les états
    useEffect(() => {
        if (data && Array.isArray(data.notifications)) {
            dispatch({
                type: 'SET_NOTIFICATIONS',
                payload: data.notifications,
            });
        }
    }, [data, user?.strongToken]);

    // Effect pour la récupération périodique des notifications
    useEffect(() => {
        const interval = setInterval(() => {
            isAuth() && refetch();
        }, 15000); // Rafraîchissement toutes les 15 secondes

        return () => clearInterval(interval);
    }, [refetch, lastKnownUuid, user?.strongToken]);

    // Fonction pour marquer une notification comme lue
    const readNotification = async (uuid) => {
        try {
            const { data } = await readNotificationMutation({ variables: { uuid } });
            if (data.readNotification === true) {
                dispatch({ type: 'MARK_AS_READ', uuid });
            }
        } catch (error) {
            console.error("Failed to mark notification as read:", error);

        }
    };

    // Fonction pour supprimer une notification
    const deleteNotification = async (uuid) => {
        try {
            const { data } = await deleteNotificationMutation({ variables: { uuid } });
            if (data?.deleteNotification === true) {
                dispatch({ type: 'DELETE_NOTIFICATION', uuid });
                // refetch();  // Ajoutez cet appel pour rafraîchir les données après suppression
            }
        } catch (error) {
            console.error("Failed to delete notification:", error);
        }
    };

    // // Fonction pour marquer toutes les notifications comme lues
    // const markAllAsRead = async (type) => {
    //     const relevantNotifications = type === 'informations' ? informations : actions;
    //     for (const notification of relevantNotifications) {
    //         if (notification.readStatus === 'UNREAD') {
    //             await readNotification(notification.uuid);
    //         }
    //     };
    //     refetch();
    // };
    const markAllAsRead = async (type) => {
        try {
            const { data } = await readAllNotificationsMutation({ variables: { notificationNature: type === 'informations' ? 'NOTIFICATION' : 'ACTION' } });
            if (data?.readAllNotifications === true) {
                dispatch({ type: 'RESET_LAST_KNOWN_UUID' });  // Réinitialise lastKnownUuid à null
                refetch({ lastKnownUuid: null });  // Refait la requête avec lastKnownUuid à null
            }
            // if (data) {
            //     // refetch(  {variables: { lastKnownUuid : ""}} );
            //     dispatch({ type: 'RESET_LAST_KNOWN_UUID' });  // Réinitialise lastKnownUuid à null
            //     refetch({ lastKnownUuid: null });  // Refait la requête avec lastKnownUuid à null

            // }
        } catch (error) {
            console.error("Failed to mark all notifications as read:", error);
        }
    };
    // Fonction pour trier les notifications
    const sortNotifications = (order, type) => {
        const sortedNotifications = type === 'information' ? [...informations] : [...actions];
        sortedNotifications.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return order === 'asc' ? dateA - dateB : dateB - dateA;
        });

        if (type === 'information') {
            dispatch({
                type: 'SET_NOTIFICATIONS',
                payload: sortedNotifications,
            });
        } else {
            dispatch({
                type: 'SET_NOTIFICATIONS',
                payload: sortedNotifications,
            });
        }
    };
    if (!user?.strongToken) return <NotificationContext.Provider>{children}</NotificationContext.Provider>

    return (
        isAuth() ?
            <NotificationContext.Provider value={{
                notifications,
                informations,
                actions,
                readNotification,
                deleteNotification,
                markAllAsRead,
                sortNotifications,
                loading,
                error,
                refetch
            }}>
                {/* {error && <div className='bg-error'>{error?.message}</div>}
            {error?.message} */}
                {error?.networkError && <NetworkConnectionLost />}

                {/* {console.log("debug error = ", { error })} */}
                {children}
            </NotificationContext.Provider>
            :
            <NotificationContext.Provider value={{}}>
                {error?.networkError && <NetworkConnectionLost />}
                {"not connected"}
                <div >isAuth égal false</div>
            </NotificationContext.Provider>

    )
}



// Hook pour utiliser le contexte
export const useNotifications = () => useContext(NotificationContext);
