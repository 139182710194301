import React, { useEffect, useRef, useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Expense from "./Expense";
import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
    SheetFooter,
} from "@/components/templates/sheet";
import FormNewExpense from "./formNewExpense";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import FormIsSend from "@/components/organisms/feedback/formIsSend";
import { Label } from "@/components/atoms/texts/label";
import PrintEmptyDataMessage from "@/components/molecules/PrintEmptyDataMessage";
import InViewComponent from "@/components/organisms/InViewComponent";
import { Toaster } from "@/components/organisms/feedback/toaster";
import { useResubmitExpenseReport } from "@/services/consultant-services/mutation/useResubmitExpenseReport";
import ImageWithProgress from "../../consultants-vision-admin/expense-account/imageWithProgress";
import useAddExpenseReportProof from "@/services/consultant-services/api-rest/useAddExpenseReportProof";

export default function ExpensePanelForReSubmit({ data, refetchFromScratch, trigger }) {
    const submitRef = useRef(null);

    const [openExpensePanel, setOpenExpensePanel] = useState(false);
    const [editFile, setEditFile] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    const { addExpenseReportProof } = useAddExpenseReportProof();
    const { resubmitExpenseReport, loading: loadingResubmit, error: errorResubmit, data: dataResubmit } = useResubmitExpenseReport();
    // console.log("ExpensePanelForReSubmit open?=", openExpensePanelResubmit)
    const handleSubmit = async (formData) => {
        console.log("Form data:", formData);
        try {
            // Réinitialiser les états
            setSubmissionError(null);
            setSubmissionSuccess(false);

            let updatedExpenseReport;
            const { file, ...restFormData } = formData; // Retirer le champ file

            if (editFile) {
                // cas 1 : Étape 1 : Envoyer le fichier et récupérer l'UUID
                const fileResponse = await addExpenseReportProof(formData.file);
                const expenseReportFileUuid = fileResponse.data.uuid;
                // Étape 2 : Envoyer la note de frais avec l'UUID du fichier
                updatedExpenseReport = {
                    ...restFormData,
                    expenseReportFileUuid // Utiliser l'UUID du fichier existant
                };
            } else {
                // cas 2 : Étape 2 seulement : Envoyer la note de frais sans l'UUID d'un fichier
                updatedExpenseReport = {
                    ...restFormData
                };
            }

            await resubmitExpenseReport(data.uuid, updatedExpenseReport);

            // Afficher le succès si tout est ok
            setSubmissionSuccess(true);
            refetchFromScratch();
        } catch (err) {
            // Gestion des erreurs
            setSubmissionError(err.message);
            console.error("Erreur lors de la soumission:", err);
        }
        return { submissionSuccess, submissionError }
    };

    const handleClose = (close) => {
        console.log("handleClose", close);
        setOpenExpensePanel(close);
        setSubmissionError(null);
        setSubmissionSuccess(false);
    }

    useEffect(() => {
        if (dataResubmit?.uuid) {
            // Afficher le succès si tout est ok
            setSubmissionSuccess(true);
            refetchFromScratch();
        }
        console.log("use effect data", dataResubmit)
        errorResubmit && setSubmissionError(errorResubmit)

    }, [errorResubmit, dataResubmit]);

    return (
        <Sheet open={openExpensePanel} onOpenChange={handleClose} className="w-full">
            <SheetTrigger className="w-full">
                {trigger}
                {/* <Button leftIcon={<AddRoundedIcon />} showLeftIcon text="consultants.expense.resubmitExpense" /> */}
            </SheetTrigger>
            <SheetContent>
                <SheetHeader
                    title={"consultants.expense.resubmitExpense"}
                    subheader={"global.information.allFieldsMandatory"}
                />
                <SheetSlot className="flex">
                    {/* {submissionError && (
                        <PrintErrorMessage error={submissionError} />
                    )} */}
                    {submissionSuccess && (
                        <FormIsSend message={<Label message="success.changesSaved"/>} />
                    )}
                    {data?.expenseReportFileUuid && !editFile && <ImageWithProgress uuidExpenseProof={data.expenseReportFileUuid} asUser />}
                    <FormNewExpense setEditFile={setEditFile} forEdition data={data} onSubmit={handleSubmit} submitRef={submitRef} submissionError={submissionError} submissionSuccess={submissionSuccess} setSubmissionSuccess={setSubmissionSuccess} setSubmissionError={setSubmissionError} />
                    {loadingResubmit && <span className="loader" />}
                </SheetSlot>
                <SheetFooter
                    buttonOutlineText={"global.actions.cancel"}
                    buttonDressedText={"global.actions.edit"}
                    warning={"consultants.expense.fileAdded"}
                    onClickOutline={(e) =>
                        handleClose(false)
                    }
                    onClickDressed={() =>
                        submitRef.current && submitRef.current.click()
                    }
                />
            </SheetContent>
        </Sheet>
    )
}
