import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Button } from './buttons/button';
import { useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogTitle, DialogOverlay } from '../ui/dialog';
import { Label } from '../atoms/texts/label';
import DialogConfirmDelete from '../organisms/dialog/DialogConfirmDelete';


export default function ControlsDownload({dataOwner, onClickNewTab, onClickDownload, onClickDelete, onClickEdit, hideDownload=false, hideOpenNewTab=false, hideDelete=false, hideEdit=false, disabledDownload=false, disabledOpenNewTab=false, disabledDelete=false, disabledEdit=false, titleForDelete = undefined}) {
    const [dialogOpen, setDialogOpen] = useState(false);

    const confirmDelete = () => {
        setDialogOpen(true);
    }

    const handleConfirmDelete = () => {
        setDialogOpen(false);
        onClickDelete();
    }

    return (
        <div className='flex justify-center items-center h-full'>
            {!hideEdit && <Button showLeftIcon leftIcon={<EditRoundedIcon />} disabled={disabledEdit} showText={false} style="ghost" sizePadding="small" className="text-night" onClick={()=>onClickEdit()}/>}
            {!hideOpenNewTab && <Button showLeftIcon leftIcon={<OpenInNewRoundedIcon />} disabled={disabledOpenNewTab} showText={false} style="ghost" sizePadding="small" className="text-night" onClick={()=>onClickNewTab()}/>}
            {!hideDownload && <Button showLeftIcon leftIcon={<DownloadRoundedIcon />} disabled={disabledDownload}  showText={false} style="ghost" sizePadding="small" className="text-night" onClick={()=>onClickDownload()}/>}
            {!hideDelete && dataOwner && <Button showLeftIcon leftIcon={<DeleteRoundedIcon />} disabled={disabledDelete}  showText={false} style="ghost" sizePadding="small" className="text-error" onClick={()=>confirmDelete()}/>}
            <DialogConfirmDelete open={dialogOpen} onClose={() => setDialogOpen(false)} onConfirm={handleConfirmDelete} title={titleForDelete}/>
        </div>
    )
}