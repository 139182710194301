import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/organisms/forms/select";
import { Label } from '@/components/atoms/texts/label';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import DragAndDropZone from '@/components/organisms/forms/DragAndDropZone';
import useConsultant from '@/components/organisms/navigation/MenuConsultant/useConsultant';
import useConsultantData from '@/components/organisms/navigation/MenuConsultant/useConsultantData';
import { useConsultantContext } from '@/context/ConsultantContext';
import useFormNewPayslip from './useFormNewPayslip';
import FormIsSend from '@/components/organisms/feedback/formIsSend';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import useAllCurrencies from '@/services/global-services/query/useAllCurrencies';
import getCurrencySymbolFromCode from '@/utils/getCurrencySymbolFromCode';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import classNames from 'classnames';

export default function FormNewPayslip({ onSubmit, submitRef, global = false, submissionSuccess, submissionError, data, forEdition = false }) {

    const vatRates = ["5%", "10%", "12%", "14%", "20%"];
    const { loading: loadingCurrencies, error: errorCurrencies, currencies } = useAllCurrencies();
    const [dateSend, setDateSend] = useState();
    const [amountSend, setAmountSend] = useState();
    const [currencySend, setCurrencySend] = useState();
    const [showInputFile, setShowInputFile] = useState(forEdition ? false : true);
    const scrollContainerRef = useRef(null);

    const { activeUser } = useConsultantContext();
    const { usersFormated } = useConsultant();
    const nameInUserFormated = activeUser && activeUser.uuid;

    const [allCurrencies, setAllCurrencies] = useState();
    const parentRef = useRef(null);
    const [isParentWidthLessThan800, setIsParentWidthLessThan800] = useState(true);

    useEffect(() => {
        currencies && setAllCurrencies(currencies);
    }, [currencies]);

    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                setIsParentWidthLessThan800(parentRef.current.offsetWidth < 800);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const scrollToTop = () => {
        // importer le hook useRef : import {useRef} from "react";
        // Déclarer la ref :     const scrollContainerRef = useRef(null);
        // ajouter au contenant : className="h-full overflow-auto" ref={scrollContainerRef} 
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0; // Définit la position de défilement à 0 pour remonter en haut
        }
    };

    function toNumber(value) {
        const number = parseFloat(value);
        return isNaN(number) ? 0 : number;  // Retourne 0 si la valeur n'est pas un nombre
    }
    useEffect(() => {
        if (submitRef) {
            submitRef.reset = {
                resetForm
            };
            submitRef.submitPayslip = {
                declineExpense
            }
        }
    }, [submitRef]);

    const declineExpense = () => {
        handleSubmitForm(e, "-1");
    }
    useEffect(() => {
        activeUser && setSelectValue(activeUser.uuid)
    }, [activeUser]);

    const {
        file,
        monthDate,
        currency,

        selectValue: consultantUuid,
        grossSalary,
        netSalary,
        taxsOnSalary,
        employeeCharges,
        employersCharges,
        comment,
        isBonus,
        uuid,
        resetForm,
        fileError,
        dateError,
        selectValueError: consultantUuidError,
        grossSalaryError,
        netSalaryError,
        taxsOnSalaryError,
        employeeChargesError,
        employersChargesError,
        commentError,
        setFile,
        setMonthDate,
        setCurrency,
        setSelectValue,
        setGrossSalary,
        setNetSalary,
        setTaxsOnSalary,
        setEmployeeCharges,
        setEmployersCharges,
        setComment,
        setIsBonus,
        setUuid,
        validateForm

    } = useFormNewPayslip(onSubmit, submitRef,

    );
    const symbol = getCurrencySymbolFromCode(currency)

    useEffect(() => {
        submissionSuccess && resetForm();
    }, [submissionSuccess]);

    useEffect(() => {
        if (data) {
            setFile(data.file || null);
            setMonthDate(data.year + "-" + (data.month < 10 ? "0" : "") + data.month || '');
            setCurrency(data.currency || 'EUR');
            setSelectValue(data.consultantUuid || '');
            setGrossSalary(data.grossSalary || '');
            setNetSalary(data.netSalary || '');
            setTaxsOnSalary(data.taxes || '');
            setEmployeeCharges(data.employeeContributions || '');
            setEmployersCharges(data.employerContributions || '');
            setComment(data.comment || '');
            setIsBonus(data.bonus || false);
            setUuid(data.uuid || '');
        }
        console.log("payslip data loaded", data);
    }, [data]);

    const handleSubmitForm = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        const valid = forEdition ? validateForm("allWithoutFile") : validateForm("all");
        const [year, month] = monthDate?.split("-");
        console.log("handleSubmitUpdate valid", valid);

        if (valid) {
            const formData = {
                file,
                payslipFileUuid: "",
                consultantUuid,
                period: monthDate + "-01",
                currency: currency,
                grossSalary,
                netSalary,
                taxes: taxsOnSalary,
                employerContributions: employersCharges,
                employeeContributions: employeeCharges,
                comment,
                bonus: isBonus,
                uuid: uuid || null
            };
            setDateSend(monthDate);
            setAmountSend(grossSalary);
            setCurrencySend(symbol);
            onSubmit(formData); // Appelle la fonction de soumission passée en props
            scrollToTop();
            // resetForm();
        } else {
            console.error("Form error detected : ", valid)
            scrollToTop();
        }
    }
    return (
        <div className="h-full overflow-auto " ref={scrollContainerRef} >

            {(dateSend && amountSend && currencySend && !forEdition) && <FormIsSend message={
                <span>
                    <Label message="success.sendForm.payslip" />
                    <Label>{dateSend}</Label>
                    <Label message="success.sendForm.forAmount" />
                    <Label>{amountSend} {currencySend}</Label>
                    <Label message="success.sendForm.continue" />
                </span>
            } />}
            {submissionError && <PrintErrorMessage error={submissionError} />}
            <form onSubmit={handleSubmitForm} className="flex flex-col gap-thirdDegree px-2 pb-4" ref={parentRef}>
                {/* <div className={`flex flex-row  ${scrollContainerRef.current && scrollContainerRef.current.offsetWidth < 800 &&'flex-col'}` } > */}
                <div className={`flex flex-row ${isParentWidthLessThan800 && 'flex-col'}`}>

                    <div className="flex flex-col gap-thirdDegree p-4 w-full">
                        <div className='flex flex-col gap-secondDegree'>

                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.supportingDocuments" />
                            {forEdition && <div className="flex items-center gap-2 w-full">
                                <Label fontWeight="" className="" message="global.actions.editFile" />
                                <Switch
                                    checked={showInputFile}
                                    onCheckedChange={(value) => setShowInputFile(value)}
                                    className=""
                                />
                            </div>}
                            {showInputFile &&
                                <DragAndDropZone file={file} setFile={setFile} fileError={fileError} accept="application/pdf, image/png, image/jpeg" />
                            }
                            {/* {forEdition && <input type="file" accept="application/pdf, image/png, image/jpeg" onChange={(e) => setFile(e.target.files[0])} />} */}
                        </div>

                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.payslip.type" />

                            <div className='flex gap-secondDegree items-center col-span-3'>

                                <Label fontWeight={!isBonus && `bold`} message="consultants.payslip.salary" />
                                <Switch
                                    checked={isBonus}
                                    onCheckedChange={setIsBonus}
                                    className="bg-white"
                                />
                                <Label fontWeight={isBonus && `bold`} message="consultants.payslip.bonus" />
                            </div>

                        </div>
                        {/* Employee name -  consultantUuidError */}
                        {!forEdition && <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.companyBenefits.consultant" />
                            <Select value={consultantUuid} onValueChange={(value) => setSelectValue(value)}  >
                                <SelectTrigger className={`col-span-3 p-2 border rounded-md text-body bg-white ${consultantUuidError === true && `border-error`} ${consultantUuidError === false && `border-success`} ${consultantUuidError == null && `border-greyStrong`}`}>
                                    <SelectValue placeholder={<Label message="global.actions.select" />} />
                                </SelectTrigger>

                                <SelectContent>
                                    <>
                                        {global
                                            ? usersFormated && usersFormated.map((user) => (
                                                <SelectItem value={user.uuid}>{user.trigram + " " + user.firstname + " " + user.lastname}</SelectItem>
                                            ))
                                            : activeUser &&
                                            <SelectItem value={activeUser.uuid}>{activeUser.trigram + " " + activeUser.firstname + " " + activeUser.lastname}</SelectItem>

                                        }
                                    </>
                                </SelectContent>
                            </Select>
                        </div>}

                        {/* Date - Date en mois/année au lieu de jj/mm/aaaa */}
                        {/* Nouveau nom de la variable à implémenter 'monthDate'*/}
                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.date" />
                            <Input
                                value={monthDate}
                                type="month"
                                onChange={(e) => { setMonthDate(e.target.value) }}
                                className={`col-span-3 p-2 border rounded-md text-body ${dateError === true && `border-error`} ${dateError === false && `border-success`} ${dateError == null && `border-greyStrong`} `}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-thirdDegree p-4 w-full">
                        {/* Currencies */}
                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.currency" />
                            {errorCurrencies ? <PrintErrorMessage error={errorCurrencies} className={'col-span-3'} /> :
                                <Select className="" value={currency} onValueChange={(e) => { setCurrency(e) }}                       >
                                    <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body `}>
                                        <SelectValue placeholder="Currency" />
                                    </SelectTrigger>
                                    <SelectContent >
                                        {loadingCurrencies ? <span className='loader' /> : (allCurrencies?.map((cur, index) => (
                                            <SelectItem key={index} value={cur.code}>
                                                <Label >{cur.code + " " + cur.symbol}</Label>
                                            </SelectItem>
                                        )))}
                                    </SelectContent>
                                </Select>}
                        </div>

                        {/*  'grossSalary'*/}
                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.payslip.grossSalary" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="number"
                                    step="0.01"  // Permet de saisir des nombres décimaux
                                    value={grossSalary}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => { setGrossSalary(e.target.value) }}
                                    placeholder={`0,00 ` + symbol}
                                    className={`p-2 border rounded-md grow text-body text-right ${grossSalaryError === true && `border-error`} ${grossSalaryError === false && `border-success`} ${grossSalaryError == null && `border-greyStrong`} `}
                                />
                                {grossSalary && symbol}
                            </div>
                        </div>

                        {/*  'taxsOnSalary'*/}
                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.payslip.taxsOnSalary" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="number"
                                    step="0.01"  // Permet de saisir des nombres décimaux
                                    value={taxsOnSalary}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => { setTaxsOnSalary(e.target.value) }}
                                    placeholder={`0,00 ` + symbol}
                                    className={`p-2 border rounded-md grow text-body text-right ${taxsOnSalaryError === true && `border-error`} ${taxsOnSalaryError === false && `border-success`} ${taxsOnSalaryError == null && `border-greyStrong`} `}
                                />
                                {taxsOnSalary && symbol}
                            </div>
                        </div>

                        {/*  'employeeCharges'*/}
                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.payslip.employeeCharges" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="number"
                                    step="0.01"  // Permet de saisir des nombres décimaux
                                    value={employeeCharges}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => { setEmployeeCharges(e.target.value) }}
                                    placeholder={`0,00 ` + symbol}
                                    className={`p-2 border rounded-md grow text-body text-right ${employeeChargesError === true && `border-error`} ${employeeChargesError === false && `border-success`} ${employeeChargesError == null && `border-greyStrong`} `}
                                />
                                {employeeCharges && symbol}
                            </div>
                        </div>

                        {/*  'employersCharges'*/}
                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.payslip.employerCharges" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <input
                                    type="number"
                                    step="0.01"  // Permet de saisir des nombres décimaux
                                    value={employersCharges}
                                    onWheel={(e) => e.target.blur()}

                                    onChange={(e) => { setEmployersCharges(e.target.value) }}
                                    placeholder={`0,00 ` + symbol}
                                    className={`p-2 border rounded-md grow text-body text-right ${employersChargesError === true && `border-error`} ${employersChargesError === false && `border-success`} ${employersChargesError == null && `border-greyStrong`} `}
                                />
                                {employersCharges && symbol}
                            </div>
                        </div>

                        {/*  'netSalary'*/}
                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.payslip.netAmount" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="number"
                                    step="0.01"  // Permet de saisir des nombres décimaux
                                    value={netSalary}
                                    onWheel={(e) => e.target.blur()}

                                    onChange={(e) => { setNetSalary(e.target.value) }}
                                    placeholder={`0,00 ` + symbol}
                                    className={`p-2 border rounded-md grow text-body text-right ${netSalaryError === true && `border-error`} ${netSalaryError === false && `border-success`} ${netSalaryError == null && `border-greyStrong`} `}
                                />
                                {netSalary && symbol}
                            </div>
                        </div>
                        {/* Comment */}
                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.comment" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="text"
                                    onWheel={(e) => e.target.blur()}
                                    value={comment}
                                    onChange={(e) => { setComment(e.target.value) }}
                                    placeholder={`---`}
                                    className={`p-2 border rounded-md grow text-body  ${commentError === true && `border-error`} ${commentError === false && `border-success`} ${commentError == null && `border-greyStrong`} `}
                                // onBlur={amountError ? validateForm("amountErrorTest") : undefined}
                                />

                            </div>
                        </div>
                    </div>

                </div>





                {/* <Separator /> */}
                {/* Net (pour vérification) = gross salary - employeeCharges - employerCharges - taxs*/}
                {/* Nouveau nom de la variable à implémenter 'netAmount'*/}
                {/* <div className='flex gap-secondDegree items-center'>
                    <div className='grow'>
                        <Label size="h3" message="consultants.payslip.netAmount" />
                    </div>
                    <Label fontWeight="bold" size="h3" color="primary">{toNumber(grossSalary) - toNumber(taxsOnSalary) - toNumber(employeeCharges) - toNumber(employersCharges)} {currency}</Label>
                </div> */}
                <Input type='submit' id="submitFormNewPayslip" className='hidden' ref={submitRef} />
            </form>
        </div>
    );
}
