import React, { useRef, useState, useEffect } from "react";
import { Button } from "@/components/molecules/buttons/button";
import PlaylistAddCheckRoundedIcon from "@mui/icons-material/PlaylistAddCheckRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import Expense from "@/components/macro-organisms/tabs/consultant-vision-user/expense-account/Expense";
import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
    SheetFooter,
    SheetClose,
} from "@/components/templates/sheet";
import FormNewExpense from "../../consultant-vision-user/expense-account/formNewExpense";
import ImageWithProgress from "./imageWithProgress";
import InViewComponent from "@/components/organisms/InViewComponent";
import { useApproveExpenseReport } from "@/services/admin-services/mutations/useApproveExpenseReport";
import { useParams } from "react-router-dom";
import { useConsultantExpenseReports } from "@/services/admin-services/query/useConsultantExpenseReports";
import PrintEmptyDataMessage from "@/components/molecules/PrintEmptyDataMessage";
// import { useExpenseReports } from "@/hooks/useExpenseReports";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import useTranslate from "@/services/global-services/useTranslate";
import { useTranslation } from "react-i18next";
import FormIsSend from "@/components/organisms/feedback/formIsSend";
// import { useApproveExpenseReport } from "@/hooks/useApproveExpenseReport";
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';

export default function ExpenseTab() {
    const [lastSlice, setLastSlice] = useState(1);
    const [expenseInApprouvalForm, setExpenseInApprouvalForm] = useState(0);
    const [expensesCopy, setExpensesCopy] = useState([]);
    const [expensesToApprove, setExpensesToApprove] = useState([]);
    const submitRef = useRef(null);
    const { approveExpenseReport, loading: loadingApprouve, error: errorApprouve, data: dataApprouve } = useApproveExpenseReport();
    const { id } = useParams();
    const [open, setOpen] = useState();
    const [t] = useTranslation("global");
    const { loading, consultantExpenseReports, error, refetch } = useConsultantExpenseReports(id, lastSlice);



    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setExpensesCopy([]);
        refetch(n);
        console.log("refetch from Scratch")
    };

    useEffect(() => {
        console.log("useEffect dataApprouve", dataApprouve?.approveExpenseReport == true)
        dataApprouve?.approveExpenseReport == true && refetchFromScratch(1)
    }, [dataApprouve])

    useEffect(() => {
        console.log("useEffect expensesCopy", expensesCopy, "consultantExpenseReports", consultantExpenseReports, "expensesCopy?.length", expensesCopy?.length, consultantExpenseReports?.expenses?.length)
        expensesCopy?.length < consultantExpenseReports?.expenses?.length && setExpensesCopy((prevExpenses) => [
            ...prevExpenses,
            ...consultantExpenseReports.expenses
        ]);
        console.log("mise à jour ?", expensesCopy?.length < consultantExpenseReports?.expenses?.length)
    }, [expensesCopy]);


    const fetchNewSlice = () => {
        // console.log("fetchNewSlice", consultantExpenseReports?.hasNext, lastSlice)
        if (!loading && !loadingApprouve && expensesCopy.length > 0 && consultantExpenseReports?.hasNext) {
            // console.log("test succesfetchNewSlice")
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };

    // Concaténation des nouvelles données avec les anciennes
    useEffect(() => {
        // console.log("use effect error, loading, consultantExpenseReports", error, loading, consultantExpenseReports, consultantExpenseReports?.expenses)
        // console.log("use effect expensesToApprove = ", expensesToApprove, "expensesCopy", expensesCopy);
        // if (loading) setExpensesCopy([]);

        if (!loading && consultantExpenseReports) {
            console.log("useEffect", consultantExpenseReports, expensesCopy)

            const filteredExpenses = consultantExpenseReports.expenses.filter(
                (expense) => expense.status === "SUBMITTED"
            );
            const sorted = filteredExpenses.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setExpensesToApprove(sorted);
            if (lastSlice == 1) {
                setExpensesCopy(() => [
                    ...consultantExpenseReports?.expenses
                ]);
            } else {
                setExpensesCopy((prevExpenses) => [
                    ...prevExpenses,
                    ...consultantExpenseReports.expenses
                ]);
            }

        }
    }, [consultantExpenseReports]);

    const handleSubmit = async (formData) => {
        try {
            console.log("executing approveExpenseReport by default formData = ", formData);
            const approvalStatus = formData.statut === "1" ? "APPROVE" : "REJECT";
            await approveExpenseReport(formData.uuid, approvalStatus);
            setExpensesToApprove((prevExpenses) => prevExpenses.slice(1));
            setExpensesCopy((prevExpenses) =>
                prevExpenses.map((expense) => {
                    if (expense.uuid === formData.uuid) {
                        return { ...expense, status: approvalStatus };
                    }
                    return expense;
                })
            );
            refetch();
        } catch (error) {
            console.error("Erreur lors de la soumission:", error);
        }
    };


    const handleDoubleClick = (uuid) => {
        // Trouver l'index de l'expense qui correspond à l'UUID reçu en paramètre
        const index = expensesToApprove.findIndex((expense) => expense.uuid === uuid);

        // Si une expense est trouvée, on met à jour l'état
        if (index !== -1) {
            // if (index) {

            setExpenseInApprouvalForm(index);
            !open && setOpen(true);
        } else {
            console.log("Expense déjà traitée, uuid = ", uuid, "index = ", index);
            // Affecter l'expense au state expensesToApprove et expenseInApprouvalForm
            setExpensesToApprove((prevExpenses) => [
                expensesCopy.find((expense) => expense.uuid === uuid),
                ...prevExpenses
            ]);
            setExpenseInApprouvalForm(0);
            setOpen(true);
            // console.error("Expense non trouvée pour l'UUID donné:", uuid);
        }
    };
    // console.log(" expensesToApprove = ", expensesToApprove, "expensesCopy", expensesCopy);
    const handleOpen = (e) => {
        // console.log("handle open, opne = ", open, "e = ", e)
        setOpen(e)
    }

    // Requête initiale lors du changement d'ID
    useEffect(() => {
        console.log("useEffect id", id)
        refetchFromScratch();
    }, [id]);

    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-auto pb-secondDegree px-1">
            <div className="flex gap-secondDegree">
                <Button style="ghost" disabled={loading} showLeftIcon leftIcon={<ReplayRoundedIcon />} onClick={() => refetchFromScratch()} showText={false} sizePadding={"firstDegree"} />

                {expensesToApprove.length > 0 ? (
                    <Sheet open={open} onOpenChange={handleOpen} >
                        <SheetTrigger>
                            <Button
                                leftIcon={<PlaylistAddCheckRoundedIcon />}
                                showLeftIcon
                                style="outline"
                                onClick={() => setExpenseInApprouvalForm(0)}

                            >{t("consultants.expense.approveExpense") + " (" + (expensesToApprove.length) + ")"}</Button>
                        </SheetTrigger>
                        <SheetContent onInteractOutside={(e) => console.log("onInteractOutside")}>
                            <SheetHeader title={"consultants.expense.approveExpense"} />
                            <SheetSlot className="flex relative">

                                {/* {console.log("expensesToApprove[0].expenseReportFileUuid =", expenseInApprouvalForm, expensesToApprove[expenseInApprouvalForm])} */}
                                {expensesToApprove.length > 0 && expensesToApprove[expenseInApprouvalForm]?.expenseReportFileUuid && <ImageWithProgress uuidExpenseProof={expensesToApprove[expenseInApprouvalForm].expenseReportFileUuid} />}
                                <FormNewExpense
                                    onSubmit={handleSubmit}
                                    submitRef={submitRef}
                                    data={expensesToApprove[expenseInApprouvalForm]}
                                    isForApprovalUseCase
                                    refetchFromScratch={refetchFromScratch}
                                />
                                {loadingApprouve && <span className="loader" />}
                                {errorApprouve && <PrintErrorMessage error={errorApprouve} />}
                                {dataApprouve?.approveExpenseReport && <FormIsSend />}
                            </SheetSlot>
                            <SheetFooter
                                buttonOutlineText={"global.actions.decline"}
                                buttonDressedText={"global.actions.approve"}
                                buttonGhostText={"global.actions.next"}
                                warning={"global.userBeNotified"}
                                onClickOutline={() => {
                                    const updatedExpenses = expensesToApprove.filter((_, index) => index !== expenseInApprouvalForm);
                                    setExpensesToApprove(updatedExpenses);
                                    submitRef.decline && submitRef.decline.declineExpense(expensesToApprove[expenseInApprouvalForm]?.uuid);
                                    setExpensesCopy((prevExpenses) =>
                                        prevExpenses.map((expense) => {
                                            if (expense.uuid === expensesToApprove[expenseInApprouvalForm]?.uuid) {
                                                return { ...expense, status: "REJECTED" };
                                            }
                                            return expense;
                                        })
                                    );
                                    (expensesToApprove?.length > expenseInApprouvalForm + 1) ? setExpenseInApprouvalForm(expenseInApprouvalForm) : setExpenseInApprouvalForm(0);
                                }}
                                onClickDressed={() => {
                                    const updatedExpenses = expensesToApprove.filter((_, index) => index !== expenseInApprouvalForm);
                                    setExpensesToApprove(updatedExpenses);
                                    submitRef.approve && submitRef.approve.approveExpense(expensesToApprove[expenseInApprouvalForm]?.uuid);
                                    setExpensesCopy((prevExpenses) =>
                                        prevExpenses.map((expense) => {
                                            if (expense.uuid === expensesToApprove[expenseInApprouvalForm]?.uuid) {
                                                return { ...expense, status: "APPROVED" };
                                            }
                                            return expense;
                                        })
                                    );
                                    console.log("DEBUG", expensesToApprove, updatedExpenses, expenseInApprouvalForm, expensesCopy);
                                    (expensesToApprove?.length > expenseInApprouvalForm + 1) ? setExpenseInApprouvalForm(expenseInApprouvalForm) : setExpenseInApprouvalForm(0);
                                }}
                                onClickGhost={() => { expensesToApprove?.length > expenseInApprouvalForm + 2 ? setExpenseInApprouvalForm(expenseInApprouvalForm + 1) : setExpenseInApprouvalForm(0) }}
                            />
                            {/* {console.log("expenseInApprouvalForm", expenseInApprouvalForm, expensesToApprove[expenseInApprouvalForm])} */}
                        </SheetContent>
                    </Sheet>
                ) : (
                    <Button
                        leftIcon={<PlaylistAddCheckRoundedIcon />}
                        disabled
                        showLeftIcon
                        style="outline"
                        text="consultants.expense.approveExpense"
                    />
                )}

                {/* <Button
                    leftIcon={<FileDownloadRoundedIcon />}
                    showLeftIcon
                    text="consultants.expense.downloadCSV"
                    onClick={handleDownload}
                /> */}
            </div>
            {error ? <PrintErrorMessage error={error} /> :

                expensesCopy?.map((item, index) => <Expense
                    key={index}
                    data={item}
                    onDoubleClick={() => handleDoubleClick(item.uuid)}
                    editable
                />)}

            {loading && <div className="w-full flex justify-center"><span className="loader " /></div>}


            {!error && !loading &&
                expensesCopy?.length == 0 &&
                <PrintEmptyDataMessage message="error.emptyDataExpense" />}
            <InViewComponent inView={fetchNewSlice} />

        </div>
    );
}
