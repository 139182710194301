import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const UPDATE_PAYSLIP = gql`
  mutation UpdatePayslip($updatedPayslip: UpdatePayslipRequestDto!) {
    updatePayslip(updatedPayslip: $updatedPayslip) {
        bonus
        comment
        createdAt
        currency
        employeeContributions
        employerContributions
        grossSalary
        month
        netSalary
        paymentDate
        payslipFileUuid
        taxes
        uuid
        year
    }
  }
`;

export const useUpdatePayslip = () => {
    const { user } = useAuth();
    const [updatePayslip, { loading, error, data }] = useMutation(UPDATE_PAYSLIP, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
    });

    return { updatePayslip, loading, error, data };
};
