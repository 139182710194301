import { useState, useEffect } from 'react';

const useFormNewPayslip = () => {
    const currencies = [
        { code: "EUR", symbol: "€" },
        { code: "USD", symbol: "$" },
        { code: "JPY", symbol: "¥" },
        { code: "GBP", symbol: "£" },
        { code: "CHF", symbol: "Fr" }
    ];
    const [file, setFile] = useState(null);
    const [currency, setCurrency] = useState("EUR");
    const [monthDate, setMonthDate] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [grossSalary, setGrossSalary] = useState('');
    const [netSalary, setNetSalary] = useState('');
    const [taxsOnSalary, setTaxsOnSalary] = useState('');
    const [employeeCharges, setEmployeeCharges] = useState('');
    const [employersCharges, setEmployersCharges] = useState('');
    const [comment, setComment] = useState('');
    const [isBonus, setIsBonus] = useState(false);
    const [uuid, setUuid] = useState('');

    const [fileError, setFileError] = useState(null);
    const [dateError, setDateError] = useState(null);
    const [selectValueError, setSelectValueError] = useState(null);
    const [grossSalaryError, setGrossSalaryError] = useState(null);
    const [netSalaryError, setNetSalaryError] = useState(null);
    const [taxsOnSalaryError, setTaxsOnSalaryError] = useState(null);
    const [employeeChargesError, setEmployeeChargesError] = useState(null);
    const [employersChargesError, setEmployersChargesError] = useState(null);
    const [commentError, setCommentError] = useState(null);


    useEffect(() => {
        file && validateForm("fileErrorTest");
    }, [file]);

    useEffect(() => {
        monthDate && validateForm("dateErrorTest");
        console.log("monthDate", monthDate);
    }, [monthDate]);

    useEffect(() => {
        selectValue && validateForm("selectValueErrorTest");
    }, [selectValue]);

    useEffect(() => {
        grossSalary && validateForm("grossSalaryErrorTest");
    }, [grossSalary]);

    useEffect(() => {
        netSalary && validateForm("netSalaryErrorTest");
    }, [netSalary]);

    useEffect(() => {
        taxsOnSalary && validateForm("taxsOnSalaryErrorTest");
    }, [taxsOnSalary]);

    useEffect(() => {
        employeeCharges && validateForm("employeeChargesErrorTest");
    }, [employeeCharges]);

    useEffect(() => {
        employersCharges && validateForm("employersChargesErrorTest");
    }, [employersCharges]);

    useEffect(() => {
        comment && validateForm("commentErrorTest");
    }, [comment]);

    useEffect(() => {
        console.log("currency change", currency, "details codee et symbol : ", currency.code + " " + currency.symbol)
    }, [currency]);

    const resetForm = () => {
        setFile(null);
        setMonthDate('');
        setSelectValue('');
        setGrossSalary('');
        setNetSalary('');
        setTaxsOnSalary('');
        setEmployeeCharges('');
        setEmployersCharges('');
        setComment('');
        setFileError(null);
        setDateError(null);
        setSelectValueError(null);
        setGrossSalaryError(null);
        setNetSalaryError(null);
        setTaxsOnSalaryError(null);
        setEmployeeChargesError(null);
        setEmployersChargesError(null);
        setCommentError(null);
        setUuid('');
    };

    function validateForm(varToTest) {
        const numericGrossSalary = parseFloat(grossSalary);
        const numericTaxsOnSalary = parseFloat(taxsOnSalary);
        const numericEmployeeCharges = parseFloat(employeeCharges);
        const numericEmployersCharges = parseFloat(employersCharges);
        const netAmount = numericGrossSalary - numericTaxsOnSalary - numericEmployeeCharges - numericEmployersCharges;
        console.log("varToTest =", varToTest);
        switch (varToTest) {
            case "all":
                setFileError(!file);
                setDateError(!monthDate);
                setSelectValueError(!selectValue);
                setGrossSalaryError(!grossSalary);
                setNetSalaryError(!netSalary);
                setTaxsOnSalaryError(!taxsOnSalary);
                setEmployeeChargesError(!employeeCharges);
                setEmployersChargesError(!employersCharges);
                setCommentError(!comment);
                break;
            case "allWithoutFile":
                setDateError(!monthDate);
                setGrossSalaryError(!grossSalary);
                setNetSalaryError(!netSalary);
                setTaxsOnSalaryError(!taxsOnSalary);
                setEmployeeChargesError(!employeeCharges);
                setEmployersChargesError(!employersCharges);
                setCommentError(!comment);
                

                return !(!monthDate || !grossSalary || !netSalary || !taxsOnSalary || !employeeCharges || !employersCharges || !comment);

            case "fileErrorTest":
                setFileError(!file);
                break;
            case "dateErrorTest":
                setDateError(!monthDate);
                break;
            case "selectValueErrorTest":
                setSelectValueError(!selectValue);
                break;
            case "grossSalaryErrorTest":
                setGrossSalaryError(!grossSalary);
                break;
            case "netSalaryErrorTest":
                setNetSalaryError(!netSalary);
                break;
            case "taxsOnSalaryErrorTest":
                setTaxsOnSalaryError(!taxsOnSalary);
                break;
            case "employeeChargesErrorTest":
                setEmployeeChargesError(!employeeCharges);
                break;
            case "employersChargesErrorTest":
                setEmployersChargesError(!employersCharges);
                break;
            case "commentErrorTest":
                setCommentError(!comment);
                console.log("commentErrorTest:", !comment, comment);

                break;
            default:
                console.error("Unknown error test:", varToTest);
                break;
        }

        if (!file || !monthDate || !selectValue || !grossSalary || !netSalary || !taxsOnSalary || !employeeCharges || !employersCharges || !comment) {
            return false;
        }
        return true;
    }

    return {
        file,
        monthDate,
        currency,
        currencies,
        selectValue,
        grossSalary,
        netSalary,
        taxsOnSalary,
        employeeCharges,
        employersCharges,
        comment,
        isBonus,
        resetForm,
        validateForm,
        fileError,
        dateError,
        selectValueError,
        grossSalaryError,
        netSalaryError,
        taxsOnSalaryError,
        employeeChargesError,
        employersChargesError,
        commentError,
        setFile,
        setMonthDate,
        setCurrency,
        setSelectValue,
        setGrossSalary,
        setNetSalary,
        setTaxsOnSalary,
        setEmployeeCharges,
        setEmployersCharges,
        setComment,
        setIsBonus,
        uuid,
        setUuid
    };
};

export default useFormNewPayslip;
