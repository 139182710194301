import { gql, useMutation } from '@apollo/client';
import { useAuth } from "@/context/AuthContext";

const RESUBMIT_EXPENSE_REPORT = gql`
  mutation ResubmitExpenseReport($expenseReportUuid: ID!, $updatedExpenseReport: ExpenseReportInputDto!) {
    resubmitExpenseReport(expenseReportUuid: $expenseReportUuid, updatedExpenseReport: $updatedExpenseReport) {
      uuid
      adminComment
      amountExcludingVat
      comment
      countryCode
      createdAt
      currency
      expenseReportFileUuid
      impactsOverflow
      invoiceDate
      invoiceIssuer
      status
      totalAmount
    }
  }
`;

export const useResubmitExpenseReport = () => {
    const { user } = useAuth();
    const [resubmitExpenseReportMutation, { loading, error, data }] = useMutation(RESUBMIT_EXPENSE_REPORT, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
    });

    const resubmitExpenseReport = async (expenseReportUuid, updatedExpenseReport) => {
        const response = await resubmitExpenseReportMutation({
            variables: {
                expenseReportUuid,
                updatedExpenseReport
            },
        });
        return response.data.resubmitExpenseReport;
    };


    return { resubmitExpenseReport, loading, error, data };
}
