import { Dialog, DialogTrigger, DialogContent, DialogTitle, DialogDescription, DialogClose, DialogFooter } from "@/components/ui/dialog";
import { useState } from "react";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import FormNewExpense from "./formNewExpense";

export default function EditAdminModal({ data, onClose, type, form, onSubmitForm }) {
    const [formData, setFormData] = useState(data);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    const defaultSubmit = () => {
        // Logique pour soumettre les modifications
        console.log("No functions linked with this button. formData is ", formData);
        onClose();
    };

    const getTitle = () => {
        switch (type) {
            case 'expense':
                return 'Modifier la note de frais';
            case 'companyBenefit':
                return 'Modifier l\'avantage société';
            case 'payslip':
                return 'Modifier la fiche de paie';
            case 'timesheet':
                return 'Modifier la feuille de temps';
            default:
                return 'Modifier';
        }
    };

    //type peut etre : expense, companyBenefit, payslip, timesheet
    // le title doit etre soit Modifier la note de frais, Modifier l'avantage société, Modifier la fiche de paie ou Modifier la feuille de temps

    return (
        <Dialog open onOpenChange={onClose} >
            <DialogContent className="max-w-[80vw] bg-medium">
                <DialogTitle>
                    <Label fontWeight="bold" size="h4">{getTitle()}</Label>
                </DialogTitle>
                <Label message="information.featureForAdminOnly"></Label>

                <DialogDescription className={"max-h-[70vh] bg-lighter rounded-sm"}>
                    {form}
                </DialogDescription>
                <DialogFooter>
                    <DialogClose  >
                        <Button text="global.actions.close" style="ghost" onClick={onClose} />
                    </DialogClose>
                    <Button className="w-full" text="global.actions.save" onClick={onSubmitForm || defaultSubmit}>Enregistrer</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
