import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/organisms/forms/select";
import { Label } from '@/components/atoms/texts/label';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import DragAndDropZone from '@/components/organisms/forms/DragAndDropZone';
import useConsultant from '@/components/organisms/navigation/MenuConsultant/useConsultant';
import useConsultantData from '@/components/organisms/navigation/MenuConsultant/useConsultantData';
import { useConsultantContext } from '@/context/ConsultantContext';
import useFormNewCompanyBenefit from './useFormNewCompanyBenefit';
import FormIsSend from '@/components/organisms/feedback/formIsSend';
import { Input } from '@/components/ui/input';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import useAllCurrencies from '@/services/global-services/query/useAllCurrencies';
import { useActiveVats } from '@/services/admin-services/query/useAllVats';
import { useTranslation } from 'react-i18next';
import { BENEFITS_TYPE } from '@/utils/globalConst';
import { Switch } from '@/components/ui/switch';

export default function FormNewCompanyBenefit({ onSubmit, submitRef, global = false, submissionError, submissionSuccess, setSubmissionError, setSubmissionSuccess, data, forEdition = false }) {
    const { loading: loadingCurrencies, error: errorCurrencies, currencies } = useAllCurrencies();
    const { loading: loadingVat, error: errorVat, data: vatRates } = useActiveVats();
    const scrollContainerRef = useRef(null);
    const [dateSend, setDateSend] = useState();
    const [amountSend, setAmountSend] = useState();
    const [currencySend, setCurrencySend] = useState();
    const { users } = useConsultantData();
    const { activeUser } = useConsultantContext();
    const { usersFormated } = useConsultant();
    const nameInUserFormated = activeUser && activeUser.uuid;
    const [t] = useTranslation("global");
    const [allCurrencies, setAllCurrencies] = useState();
    const [showInputFile, setShowInputFile] = useState(forEdition ? false : true);
    const [vatNotAvailable, setVatNotAvailable] = useState({});
    const [isParentWidthLessThan800, setIsParentWidthLessThan800] = useState(true);
    const parentRef = useRef(null);

    useEffect(() => {
        currencies && setAllCurrencies(currencies);
    }, [currencies]);

    useEffect(() => {
        if (submitRef) {
            submitRef.reset = {
                resetForm
            };
        }
    }, [submitRef]);
    
    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                setIsParentWidthLessThan800(parentRef.current.offsetWidth < 800);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const sendCompanyBenefit = () => {
        handleSubmitForm(e, "-1");
    }

    const {
        file,
        date,
        period,
        currency,
        selectValue,
        amount,
        vatValues,
        totalVat,
        comment,
        benefitType,
        uuid,
        handleVatChange,
        resetForm,
        fileError,
        dateError,
        periodError,
        selectValueError,
        amountError,
        totalVatError,
        benefitTypeError,
        commentError,
        setFile,
        setDate,
        setPeriod,
        setCurrency,
        setSelectValue,
        setAmount,
        setVatValues,
        setComment,
        setBenefitType,
        setUuid,
        validateForm
    } = useFormNewCompanyBenefit(onSubmit, submitRef, nameInUserFormated, setSubmissionError, setSubmissionSuccess);

    const scrollToTop = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const valid = forEdition ? validateForm("allWithoutFile") : validateForm("all");
        const vatList = Object.entries(vatValues).map(([vatUuid, vatData]) => ({
            vatUuid: vatUuid,
            vatAmount: vatData
        }));

        if (valid) {
            const formData = {
                file,
                companyBenefitInvoiceFileUuid: "",
                consultantUuid: selectValue || nameInUserFormated,
                period: period + "-01",
                invoiceDate: date,
                currency: currency,
                amountExcludingVat: amount,
                totalAmount: (toNumber(totalVat) + toNumber(amount)).toFixed(2),
                comment: comment,
                vatList: vatList,
                benefitType: benefitType,
                uuid: uuid || null
            };
            setDateSend(date);
            setAmountSend(amount);
            setCurrencySend(currency);
            onSubmit(formData);
            scrollToTop();
        } else {
            console.error("Form error detected : ", valid)
            scrollToTop();
        }
    }

    useEffect(() => {
        submissionSuccess && resetForm();
        setSubmissionSuccess(false);
    }, [submissionSuccess]);

    useEffect(() => {
        (activeUser || !selectValue) && setSelectValue(activeUser?.uuid);
    }, [activeUser, selectValue]);

    useEffect(() => {
        if (data && vatRates && !Object.keys(vatValues).length) {
            setFile(data.file || null);
            setDate(data.invoiceDate || '');
            setPeriod(data.year + "-" + (data.month < 10 ? "0" : "") + data.month || '');
            setCurrency(data.currency || 'EUR');
            setSelectValue(data.consultantUuid || '');
            setAmount(data.amountExcludingVat || '');
            setComment(data.comment || '');
            setBenefitType(data.benefitType || '');
            setUuid(data.uuid || '');

            const vatMapping = data.vatList.reduce((acc, { vatAmount, vatRate, vatUuid }) => {
                const vat = vatRates?.find(v => v.uuid === vatUuid);
                console.log(vatRates, data.vatList)
                if (vat) {
                    acc[vat.uuid] = vatAmount;
                } else {
                    console.error("Vat UUID not found:", vatUuid, vatRates);
                    setVatNotAvailable(prev => ({ ...prev, [vatRate]: vatAmount }));
                }
                return acc;
            }, {});

            setVatValues(vatMapping);
        }
    }, [data, vatRates]);

    function ListingConsultantsForSelect() {
        if (global) {
            return (
                <>
                    {usersFormated?.map((user) => (<SelectItem value={user.uuid}>{user.trigram + " " + user.firstname + " " + user.lastname}</SelectItem>))}
                </>
            )
        } else {
            return (
                activeUser && <SelectItem value={activeUser.uuid}>{activeUser.trigram + " " + activeUser.firstname + " " + activeUser.lastname}</SelectItem>
            )
        }
    }

    function toNumber(value) {
        const number = parseFloat(value);
        return isNaN(number) ? 0 : number;
    }


    return (
        <div className="h-full overflow-auto" ref={scrollContainerRef} >
            {submissionError && <PrintErrorMessage error={submissionError} />}
            {submissionSuccess && <FormIsSend message={
                <span>
                    <Label message="success.sendForm.companyBenefits" />
                    <Label>{dateSend}</Label>
                    <Label message="success.sendForm.forAmount" />
                    <Label>{amountSend} {currencySend}</Label>
                    <Label message="success.sendForm.continue" />
                </span>
            } />}
            <form onSubmit={handleSubmitForm} className="flex flex-col gap-thirdDegree px-2 pb-4" ref={parentRef} >
                <div className={`flex flex-row ${isParentWidthLessThan800 && 'flex-col'}`}>
                    <div className="flex flex-col gap-thirdDegree p-4 w-full">
                        <div className='flex flex-col gap-secondDegree'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.supportingDocuments" />
                            {forEdition && <div className="flex items-center gap-2 w-full">
                                <Label fontWeight="" className="" message="global.actions.editFile" />
                                <Switch
                                    checked={showInputFile}
                                    onCheckedChange={(value) => setShowInputFile(value)}
                                    className=""
                                />
                            </div>}
                            {showInputFile &&
                                <DragAndDropZone file={file} setFile={setFile} fileError={fileError} accept="application/pdf, image/png, image/jpeg" />
                            }
                        </div>

                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.companyBenefits.consultant" />
                            <Select value={selectValue || (global ? undefined : nameInUserFormated)} onValueChange={(value) => setSelectValue(value)}  >
                                <SelectTrigger className={`col-span-3 p-2 border rounded-md text-body bg-white ${selectValueError === true && `border-error`} ${selectValueError === false && `border-success`} ${selectValueError == null && `border-greyStrong`}`}>
                                    <SelectValue placeholder={<Label message="global.actions.select" />} />
                                </SelectTrigger>
                                <SelectContent>
                                    <ListingConsultantsForSelect />
                                </SelectContent>
                            </Select>
                        </div>

                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.companyBenefits.benefitType" />
                            <Select className="" value={benefitType} onValueChange={(e) => { setBenefitType(e) }}                       >
                                <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body ${benefitTypeError === true && `border-error`} ${benefitTypeError === false && `border-success`} ${benefitTypeError == null && `border-greyStrong`} `}>
                                    <SelectValue placeholder={<Label text="global.actions.select" />}></SelectValue>
                                </SelectTrigger>
                                <SelectContent >
                                    {BENEFITS_TYPE?.map((type, index) => (
                                        <SelectItem key={index} value={type.enum}>
                                            <Label message={`consultants.companyBenefits.` + type.label}></Label>
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.date" />
                            <Input
                                type="date"
                                value={date}
                                onChange={(e) => { setDate(e.target.value) }}
                                className={`col-span-3 p-2 border rounded-md text-body ${dateError === true && `border-error`} ${dateError === false && `border-success`} ${dateError == null && `border-greyStrong`} `}
                            />
                        </div>

                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.companyBenefits.period" />
                            <Input
                                type="month"
                                value={period}
                                onChange={(e) => { setPeriod(e.target.value) }}
                                className={`col-span-3 p-2 border rounded-md text-body ${periodError === true && `border-error`} ${periodError === false && `border-success`} ${periodError == null && `border-greyStrong`} `}
                            />
                        </div>

                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.comment" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="text"
                                    onWheel={(e) => e.target.blur()}
                                    value={comment}
                                    onChange={(e) => { setComment(e.target.value) }}
                                    placeholder={`---`}
                                    className={`p-2 border rounded-md grow text-body  ${commentError === true && `border-error`} ${commentError === false && `border-success`} ${commentError == null && `border-greyStrong`} `}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-thirdDegree p-4 w-full">
                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.currency" />
                            {errorCurrencies ? <PrintErrorMessage error={errorCurrencies} className={'col-span-3'} /> :
                                <Select className="" value={currency} onValueChange={(e) => { setCurrency(e) }}                       >
                                    <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body `}>
                                        <SelectValue placeholder="Currency" />
                                    </SelectTrigger>
                                    <SelectContent >
                                        {loadingCurrencies ? <span className='loader' /> : (allCurrencies?.map((cur, index) => (
                                            <SelectItem key={index} value={cur.code}>
                                                <Label >{cur.code + " " + cur.symbol}</Label>
                                            </SelectItem>
                                        )))}
                                    </SelectContent>
                                </Select>}
                        </div>

                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.amountExclTaxs" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="number"
                                    step="0.01"
                                    value={amount}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => { setAmount(e.target.value) }}
                                    placeholder={`0,00 ` + currency}
                                    className={`p-2 border rounded-md grow text-body text-right ${amountError === true && `border-error`} ${amountError === false && `border-success`} ${amountError == null && `border-greyStrong`} `}
                                />
                                {currency}
                            </div>
                        </div>

                        {/* Ventilation de la TVA */}
                        <div>
                            <Label fontWeight="bold" message="consultants.expense.VATBreakdown" />
                            <h3 className="font-bold mb-2"></h3>

                            <div className="flex flex-row gap-secondDegree justify-center items-center grid grid-cols-4">

                                <div className="flex flex-col gap-2 grow col-span-3">


                                    {
                                        (errorVat ? <PrintErrorMessage error={errorVat} className={'col-span-3'} /> :
                                            loadingVat ? <span className='loader' /> : (vatRates.map((item, index) => (
                                                <div key={index} className='grid grid-cols-3 gap-firstDegree items-center text-right'>
                                                    <div className="col-span-1 pr-2">
                                                        <Label fontWeight="semibold">{item.rate} %</Label>
                                                    </div>
                                                    <Input
                                                        type="number"
                                                        step="0.01"
                                                        min="0"
                                                        name={`item${index}`}
                                                        onWheel={(e) => e.target.blur()}
                                                        value={vatValues[item.uuid]?.value || vatValues[item.uuid] || undefined}
                                                        onChange={(e) => { handleVatChange(e, item) }}
                                                        placeholder={t("consultants.expense.amountVAT")}
                                                        className="p-2 border border-greyStrong rounded-md text-body col-span-2 text-right"
                                                    />
                                                </div>
                                            ))))
                                    }
                                </div>
                                <div className='grow flex flex-col gap-firstDegree items-center text-right col-span-1'>
                                    <span><Label fontWeight="semibold" message="consultants.expense.totalVAT" /> : </span>
                                    <Label>{totalVat?.toFixed(2)} {currency}</Label>
                                </div>
                            </div>
                            {Object.keys(vatNotAvailable).length > 0 && (
                                <>
                                    <Label>Les taux suivants ne sont plus disponibles, veuillez modifier votre saisie : </Label>
                                    {Object.entries(vatNotAvailable).map(([vatRate, vatAmount], index) => (
                                        <div key={index} className='gap-firstDegree items-center text-left'>
                                            <Label fontWeight="semibold">{vatRate} % : </Label>
                                            <Label className="col-span-2">{vatAmount} {currency}</Label>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>

                        <Separator />
                        {/* Montant HT */}
                        <div className='flex gap-secondDegree items-center'>
                            <div className='grow'>
                                <Label size="h3" message="consultants.expense.amountInclTaxs" />
                            </div>
                            <Label fontWeight="bold" size="h3" color="primary">{(toNumber(totalVat) + toNumber(amount)).toFixed(2)} {currency}</Label>
                        </div>
                    </div>
                </div>
                <Input type='submit' id="submitFormNewCompanyBenefit" className='hidden' ref={submitRef} />
            </form >
        </div >
    );
}
