import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const UPDATE_COMPANY_BENEFIT_INVOICE = gql`
  mutation UpdateCompanyBenefitInvoice($updatedCompanyBenefitInvoice: UpdateCompanyBenefitInvoiceRequestDto!) {
    updateCompanyBenefitInvoice(updatedCompanyBenefitInvoice: $updatedCompanyBenefitInvoice) {
      amountExcludingVat
      amountExcludingVatEur
      benefitType
      comment
      companyBenefitInvoiceFileUuid
      createdAt
      currency
      invoiceDate
      month
      totalAmount
      totalAmountEur
      uuid
      vatList {
        vatAmount
        vatRate
      }
      year
    }
  }
`;

export const useUpdateCompanyBenefitInvoice = () => {
    const { user } = useAuth();
    const [updateCompanyBenefitInvoice, { loading, error, data }] = useMutation(UPDATE_COMPANY_BENEFIT_INVOICE, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
    });

    return { updateCompanyBenefitInvoice, loading, error, data };
};
