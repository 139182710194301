

import React, { useEffect, useRef, useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Expense from "./Expense";
import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
    SheetFooter,
} from "@/components/templates/sheet";
import FormNewExpense from "./formNewExpense";
import useExpenseData from "./useExpenseDataUser";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import FormIsSend from "@/components/organisms/feedback/formIsSend";
import { Label } from "@/components/atoms/texts/label";
import useAddExpenseReportProof from "@/services/consultant-services/api-rest/useAddExpenseReportProof";
import { useAddExpenseReport } from "@/services/consultant-services/mutation/useAddExpenseReport";
import { useExpenseReports } from "@/services/consultant-services/query/useExpenseReports";
import PrintEmptyDataMessage from "@/components/molecules/PrintEmptyDataMessage";
import InViewComponent from "@/components/organisms/InViewComponent";
import { Toaster } from "@/components/organisms/feedback/toaster";
import ExpensePanel from "./expensePanel";
import ExpensePanelForReSubmit from "./expensePanelForReSubmit";

export default function ExpenseTabUser() {
    const [lastSlice, setLastSlice] = useState(1);
    const { loading, error, expenseReports, refetch } = useExpenseReports(lastSlice); // Utilisation de useExpenseReports

    const [expensesCopy, setExpensesCopy] = useState([]);


    useEffect(() => {
        console.log("ExpenseTabUser, premier montage expensesCopy", expensesCopy)
        refetchFromScratch();
    }, []);

    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setExpensesCopy([]);
        refetch(n);
        console.log(n);
    };

    const fetchNewSlice = () => {

        if (!loading && expensesCopy.length > 0 && expenseReports?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };
    const handleDoubleClick = (uuid) => {

        console.log("Expense refusé à modifier, uuid = ", uuid);

    };

    useEffect(() => {
        console.log("useEffect expensesCopy", expensesCopy, "expenseReports", expenseReports, "expensesCopy?.length", expensesCopy?.length, expenseReports?.expenses?.length)
        expensesCopy?.length < expenseReports?.expenses?.length && setExpensesCopy((prevExpenses) => [
            ...prevExpenses,
            ...expenseReports.expenses
        ]);
    }, [expensesCopy]);

    // Concaténation des nouvelles données avec les anciennes
    useEffect(() => {
        if (!loading && expenseReports) {
            // Mettre à jour la copie des dépenses avec les nouvelles données
            if (lastSlice == 1) {
                setExpensesCopy(() => [
                    ...expenseReports?.expenses
                ]);
            } else {
                setExpensesCopy((prevExpenses) => [
                    ...prevExpenses,
                    ...expenseReports?.expenses
                ]);
            }
            console.log("mise à jour de expense Copy", expensesCopy, "+", expenseReports.expenses, "loading", loading, "expenseReports", expenseReports);
        }
    }, [expenseReports]);



    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-auto pb-secondDegree px-1">
            <Toaster />

            <ExpensePanel refetchFromScratch={refetchFromScratch} />
            {error ? <PrintErrorMessage error={error} /> :
                expensesCopy?.map((item, index) => (
                    item?.status == "REJECTED" ? 
                    <ExpensePanelForReSubmit data={item} refetchFromScratch={refetchFromScratch} trigger={<Expense key={index} data={item} dataOwner refetch={refetchFromScratch} onDoubleClick={() => item?.status == "REJECTED" && handleDoubleClick(item.uuid)}/> }/>
                    : <Expense key={index} data={item} dataOwner refetch={refetchFromScratch} onDoubleClick={() => item?.status == "REJECTED" && handleDoubleClick(item.uuid)}
                    />
                ))}

            {!error && !loading &&
                expenseReports?.expenses?.length == 0 &&
                <PrintEmptyDataMessage message="error.emptyDataExpense" />}

            {loading && <div className="w-full flex justify-center"><span className="loader " /></div>}

            <InViewComponent inView={fetchNewSlice} />
        </div>
    );
}
