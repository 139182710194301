import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useEffect } from "react";

const GET_CONSULTANT_COMPANY_BENEFITS_INVOICES = gql`
  query ConsultantCompanyBenefitInvoices($consultantUuid: ID!, $sliceNumber: Int!) {
    consultantCompanyBenefitInvoices(companyBenefitInvoicesRequest: {
      consultantUuid: $consultantUuid
      sliceNumber: $sliceNumber
    }) {
      companyBenefits {
        uuid
        companyBenefitInvoiceFileUuid
        year
        month
        invoiceDate
        amountExcludingVat
        totalAmount
        comment
        benefitType
        vatList {
          vatRate
          vatAmount
          vatUuid
        }
      }
      hasNext
    }
  }
`;

export const useConsultantCompanyBenefitInvoices = (consultantUuid, sliceNumber) => {
    const { user } = useAuth();

    const { loading, error, data, refetch } = useQuery(GET_CONSULTANT_COMPANY_BENEFITS_INVOICES, {
        variables: { consultantUuid, sliceNumber },
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        // console.log("sliceNumber change", sliceNumber)
        refetch(sliceNumber)
    }, [sliceNumber]);

    return { loading, error, data: data?.consultantCompanyBenefitInvoices, refetch };
};
